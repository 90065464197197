// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `




.divAddCard{
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.AddCardStyle{
    display: flex;

    font-size: 14px;
    align-items: center;
    
    border-radius: 16px;
    border: solid 1px #6F6767;
    background-color: transparent;
    color: #6F6767;
}


.AddCardStyle #add{
    border: solid 1px #6F6767;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.AddCardStyle #infoAdd{
    margin-left: 5px;
}

#ExtraInfo{
    color: #6F6767;
    font-size: 12px;
    padding-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/AddCard/AddCard.css"],"names":[],"mappings":";;;;;AAKA;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;AACrB;AACA;IACI,aAAa;;IAEb,eAAe;IACf,mBAAmB;;IAEnB,mBAAmB;IACnB,yBAAyB;IACzB,6BAA6B;IAC7B,cAAc;AAClB;;;AAGA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["\n\n\n\n\n.divAddCard{\n    display: flex;\n    justify-content: center;\n    padding-top: 10px;\n}\n.AddCardStyle{\n    display: flex;\n\n    font-size: 14px;\n    align-items: center;\n    \n    border-radius: 16px;\n    border: solid 1px #6F6767;\n    background-color: transparent;\n    color: #6F6767;\n}\n\n\n.AddCardStyle #add{\n    border: solid 1px #6F6767;\n    width: 24px;\n    height: 24px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n}\n\n.AddCardStyle #infoAdd{\n    margin-left: 5px;\n}\n\n#ExtraInfo{\n    color: #6F6767;\n    font-size: 12px;\n    padding-top: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
