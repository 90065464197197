import * as React from "react"; 
import { Container, Row, Col, Button, Image, Card, Form} from "react-bootstrap"; 
import './Pagamento.css'
import Menu from "../../components/menu/menu";
import {useState, useEffect} from 'react'
import CardFooterNext from "../../components/CardFooter_gonext/CardFooter_gonext";
import DivSpace from "../../components/DivSpace/DivSpace";
import StepsPage from "../../components/StepsPage/StepsPage";
import { useDispatch, useSelector } from "react-redux";
import * as url from '../../backend.json'
import order, { AddOrder } from "../../store/reducers/Prenotazione/order";
const INFO=url
function PagamentoPage() { 
    const [btn, setBtn]=React.useState(null)
    const carelloState=useSelector(state=>state["carello"])
    const dispatch=useDispatch()
    const [totalPrice, setTotalPrice]=useState([])
    const [activatePayment, setActivate]=React.useState(null)
    const [Carta, setCarta]=React.useState({
        "tipo":0,
        "num":"",
        "Data di scadenza":"",
        "CVV": ""
    })

    const kindofPayment=["bonifico", "carta", "google", "satispay", "paypal"]
    const [total, settotal]=useState(0)
    useEffect(()=>{
        // console.log(carelloState)
        let tmp_show=[]
        let tmp_price=0
        let tmp_total=[]
        let product_price=0;
        carelloState.carello.prenotazioni.map((item, index)=>{
            tmp_price=0
            // tmp_price+=3.0
            product_price=0
            item.consegne.map((consegna, indexC)=>{
                // console.log(consegna)
                // console.log(parseFloat(consegna.prodotti.price)*consegna.prodotti.n*22/100)
                product_price+=(parseFloat(consegna.prodotti.price)*consegna.prodotti.n )
                if(consegna.info["zona pedonale"]){
                    tmp_price+=2;
                }
            })
            // console.log("production price", index,product_price, product_price*22/100)
            tmp_price+=product_price+product_price*22/100
            
            tmp_total=tmp_total.concat(tmp_price.toFixed(2))
            tmp_show.push(false)
        })
        
        // console.log("total",tmp_total)
        setTotalPrice(tmp_total)


    }, [carelloState])
    function add(accumulator, a) {
        return accumulator + a;
      }

    function TaskJson(input, type, index){
        console.log(input)
        // if(type=="pick_up"){
        //     input.info=input.info.info
        // }
        console.log(type)
        console.log(input.info.extraprice)
        let array=input.info.extrainfoAddress.address_components
        let single_task={
            type:type,
            cli_first_name:input.info["Nome"],
            cli_last_name:input.info["Cognome"],
            cli_company:"",
            cli_citofono:"",
            cli_phone: type=="pick_up" ? input.info["telefono"]:input.info["Telefono"] ,
            cli_email:input.info["Email"],
            cli_addr_via:input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("route"))]? input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("route"))].long_name: "",
            cli_addr_n_civ:input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("street_number"))]? input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("street_number"))].long_name:"",
            cli_addr_cap:input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("postal_code"))]?input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("postal_code"))].long_name: "",
            cli_addr_city:input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("locality"))]?input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("locality"))].long_name: "",
            cli_addr_local:input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("locality"))]?input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("locality"))].long_name: "",
            cli_addr_prov:input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("administrative_area_level_2"))]?input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("administrative_area_level_2"))].short_name: "",
            cli_addr_country:input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("country"))]?input.info.extrainfoAddress.address_components[array.findIndex(item => item.types.includes("country"))].long_name: "",
            note: input.info["Nota"],
            slot1_from: type=="pick_up" ? input.data.data.replaceAll("/","-")+" "+input.data.h : input.info.data.replaceAll("/","-")+" "+input.info.h.split("-")[0] ,
            slot1_to:type=="pick_up" ? input.data.data.replaceAll("/","-")+" "+"23:59" : input.info.data.replaceAll("/","-")+" "+input.info.h.split("-")[1],
            slot2_from:"",
            slot2_to:"",
            price: type=="pick_up" ? "3" : totalPrice[index],
            metadata:{
                "product_price":type=="pick_up" ? "3" : totalPrice[index],
                "extra_price":{
                    express:input.info.extraprice?.express? "2.0": "0",
                    holiday:input.info.extraprice?.festivo? "2.0": "0",
                    extratime:input.info.extraprice? "2.0": "0",
                    extracap:input.info.extraprice? "2.0": "0",
                    ped_zone:input.info.extraprice?.pedonale? "2.0": "0"
                }
            }
        }
        return single_task
    }
     
     function CreateJson(disable){

        let tmpTask=[]
        let orders=[]
        carelloState.carello.prenotazioni.map((item, index)=>{
            tmpTask=[]
            // console.log("totalPrice", ParseFloat(totalPrice.reduce(add, 0)), typeof totalPrice.reduce(add, 0))
            let tmp={
                "external_id": "webapp",
                "transaction_id": ""+Math.random()+"//"+Math.random(),
                "transaction_result": "success",
                "transaction_method": kindofPayment[activatePayment],
                "price":""+parseFloat(totalPrice.reduce(add, 0)),
                "metadata":{
                    "product_price":3,
                    "metadata":{
    
                    }
                }
            }
          
            tmpTask=tmpTask.concat(TaskJson(item.ritiro, "pick_up"))
       
            item.consegne.map((cons, index_c)=>{
                
                tmpTask= tmpTask.concat(TaskJson(cons, "drop_off", index_c))
                tmpTask[tmpTask.length-1].metadata["quantity"]=cons.prodotti.n
                tmpTask[tmpTask.length-1].metadata["product"]=cons.prodotti.name
                tmpTask[tmpTask.length-1].metadata["product_label"]=cons.prodotti.name
                tmpTask[tmpTask.length-1].metadata["product_category"]=cons.categoria.name
                tmpTask[tmpTask.length-1].metadata["product_category_label"]=cons.categoria.name
                tmpTask[tmpTask.length-1].metadata["product_price"]=cons.prodotti.price
                tmpTask[tmpTask.length-1].metadata["fragile"]=cons.info.trasportoPrice.fragile
                tmpTask[tmpTask.length-1].metadata["temp_ctrl"]=cons.info.trasportoPrice.temperatura
                tmpTask[tmpTask.length-1].metadata["hang"]=cons.info.trasportoPrice.appendere

            })
            tmp["tasks"]=tmpTask
            // console.log("___result",tmp)
            orders.push(tmp)
        })
        // console.log("___result", orders)
        return orders
        // tmpTask

    }
    function methodCard(index){
        
        setBtn(index)
        let result=CreateJson()
        // dispatch(AddOrder(result))
    }
    function selectType(index){
    
        let result=CreateJson()
        console.log("___result",result)
        dispatch(AddOrder(result))
        setActivate(index)
        console.log(activatePayment)
    }

    return (

        <div className="App">
        <Menu></Menu>
       
        
        <CardFooterNext step={4}></CardFooterNext>
        <DivSpace distancepx={"200px"}></DivSpace>
        
        <div className="CardDiv" width={"70%"}>
        <StepsPage position={4}></StepsPage>
        <DivSpace distancepx={"20px"}></DivSpace>
            <Container clasName="containerPay">
                <Row>
                    <Col className="colPay"><Button active={activatePayment==0} className="payBtn" onClick={e=>{selectType(0)}}><img width={"30px"}src="/bonifico.png"></img><p>Bonifico Bancario</p></Button></Col>
                    <Col className="colPay"><Button  active={activatePayment==1}  className="payBtn" onClick={e=>{selectType(1)}}><img width={"30px"}src="/carta.png"></img><p>Carta di Credito</p></Button></Col>
                    <Col className="colPay"><Button  active={activatePayment==2}  className="payBtn" onClick={e=>{selectType(2)}}><img width={"60px"}src="/google-pay 1.png"></img><p>Google Pay</p></Button></Col>
                    <Col className="colPay"><Button  active={activatePayment==3}  className="payBtn" onClick={e=>{selectType(3)}}><img width={"30px"}src="/satispay.png"></img><p>Satispay</p></Button></Col>
                    <Col className="colPay"><Button  active={activatePayment==4}  className="payBtn" onClick={e=>{selectType(4)}}><img width={"30px"}src="/paypal.png"></img><p>Paypal</p></Button></Col>
                </Row>
                <DivSpace distancepx={"50px"}></DivSpace>
                <Row>
                    <Card className="CardPagamento">
                        <Card.Body>
                            {activatePayment==1 ? <Container>
                                <Row>
                                    <Col>
                                    <Button onClick={e=>{methodCard(0)}} active={btn==0} className="buttonMethod"><img width={30} src="/master.png"></img></Button>
                                        </Col>
                                        <Col>
                                    <Button onClick={e=>{methodCard(1)}} active={btn==1} className="buttonMethod"><img width={30} src="/visa.png"></img></Button>
                                        </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <Form.Label  className="labelStyle">{"Numero di Carta"}</Form.Label>
                                    <Form.Control 
                                        type={"number"}
                                        id={"asda"}
                                        key={"num carta"}
                                        defaultValue={""}
                                        placeholder="0000 0000 0000 0000"
                                        onChange={e=>{console.log(e)}}
                                    
                                    />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <Form.Label  className="labelStyle">{"Data di scadenza"}</Form.Label>
                                    <Form.Control className=""
                                        type={"text"}
                                        id={"datesca"}
                                        key={"date carta"}
                                        defaultValue={""}
                                        
                                        pattern={"(0[1-9]|10|11|12)/20[0-9]{2}$"}
                                        placeholder="MM/YY"
                                        onChange={e=>{console.log(e)}}
                                    
                                        
                                    
                                    />
                                    </Col>
                                    <Col>
                                    <Form.Label  className="labelStyle">{"CVV"}</Form.Label>
                                    <Form.Control className=""
                                        type={"password"}
                                        id={"asda"}
                                        key={"cvv"}
                                        defaultValue={""}
                                        placeholder="***"
                                        onChange={e=>{console.log(e)}}
                                    
                                    />
                                    </Col>
                                </Row>
                            </Container>: <></>}
                            {
                                activatePayment==0 ? <div className="PaymentInfo">

                                <h3>Questo sistema di pagamento richiede fino a 3+ giorni lavorativi. </h3>
                                <h3>{"La gestione degli ordini B2B (tra busket-line e la vostra società) deve essere prevativamente concordata."} </h3>
                                </div>:<></>
                            }
                                                        {
                                activatePayment==2 ? <div  className="PaymentInfo">

                                <h3>Google Pay tramite Nexi XPay </h3>
                                
                                </div>:<></>
                            }
                                                        {
                                activatePayment==3 ? <div  className="PaymentInfo">

                                <h3>Paga smart, con Satispay hai tutto a portta di app! </h3>
                                
                                </div>:<></>
                            }
                                                                                    {
                                activatePayment==4 ? <div  className="PaymentInfo">

                                <h3>Paga con PayPal!</h3>
                                
                                </div>:<></>
                            }
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </div>
        </div>
    )
} 

export default PagamentoPage;