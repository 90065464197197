

import { Button, Card, Form, InputGroup,  } from "react-bootstrap";
import './LoginForm.css'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useState } from "react";
import * as url from '../../../backend.json'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UseDispatch, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'
import { activateAccount, updateAccount } from "../../../store/reducers/Account/reducer";
const baseurl=url;


export const loginSession = (credentials) => {
    return new Promise((resolve, reject) => {
      axios.post(
        baseurl.API + 'accounts/login/', 
        credentials, 
        {
          withCredentials: true, // Abilita l'invio dei cookie nella richiesta
        }
      ).then(response => {

        if (response.status !== 200) {
          reject(new Error('Credenziali non valide'));
        }
  
       
        resolve(response.data); // Risolve la Promise quando la chiamata post ha avuto successo
      }).catch(error => {
        Swal.fire({
          text: error?.response?.data?.error,
          heightAuto: false,
          icon: "error"
        })
        console.error('Errore durante la richiesta di login:', error);
        reject(error); // Rigetta la Promise se si verifica un errore durante la chiamata
      });
    });
  };
function LoginForm(){
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);   
    const [username_, setusername]=useState("")
    const [psw, setPsw]=useState("")
    const [loginJson, setLogin]=useState({
        username:"",
        password:""
    })
    const [CheckCredential, setCheck]=useState({
      username:{
        error: false,
        message:""
      },
      password:{
        error: false,
        message:""
      }
    })
    const handleToggle = () => {
      if (type==='password'){
         setIcon(eye);
         setType('text')
      } else {
         setIcon(eyeOff)
         setType('password')
      }
   }
    function update(key, value){
        loginJson[key]=value
        
    }

    const Login = async () => {
      if(loginJson.username==""){
       
 
        setCheck({... {username: {error:true, message:"field mandatory"}}})
        
      }
        
      if(loginJson.password==""){
        setCheck({... {password: {error:true, message:"field mandatory"}}})

      }
      console.log(CheckCredential)
      try {

    
        // Esegui la chiamata post in modo sincrono utilizzando una Promise
        const isLoggedIn = await loginSession(loginJson);
        console.log(isLoggedIn)
        if (isLoggedIn) {
          if(isLoggedIn.account_state=="active"){
            dispatch(activateAccount())
          }
          AccountInfo()
          // navigate.go
          // window.location.href="/"
          // Reindirizza il browser verso un'altra pagina web
          // window.location.href = '/'; // Sostituisci con l'URL della pagina di destinazione
        }
    
      } catch (error) {
        console.error('Errore durante il login:', error);
      }
    };



    function AccountInfo(){
        axios.get(baseurl.API+"accounts/profile/", {withCredentials: true}).then(e=>{
          dispatch(updateAccount(e.data))
          navigate("/");
        }).catch(err=>{
            console.log(err)
        })
    }
    return (
        <>
         <h1>Welcome Back!</h1>
         <div className="PasswordDiv">
            <InputGroup
            controlId="floatingTextarea_usr"
            label="Username"
            className="mb-3"
        >
            <Form.Control id="email" isInvalid={CheckCredential?.username?.error} type="email" placeholder="example@email.com" onChange={e=>{update("username", e.target.value)}} />
            
            <Form.Control.Feedback type="invalid">
            {CheckCredential?.username?.message}
          </Form.Control.Feedback>
        </InputGroup>
        </div>
        
        <div className="PasswordDiv">
        <InputGroup
            controlId="floatingTextarea_psw"
            label="Password"
            className="mb-3"
        >
            
            <Form.Control isInvalid={CheckCredential?.password?.error} type={type} placeholder="password" onChange={e=>{update("password", e.target.value)}}>
              
            </Form.Control>
            <Button className="BtnPsw" onClick={handleToggle}>
            <Icon class=" mr-10" icon={icon} size={25}/>
            </Button>
            <Form.Control.Feedback type="invalid">
            {CheckCredential?.password?.message}
          </Form.Control.Feedback>
          
        </InputGroup>

        </div>

                <Button className="loginButton" onClick={Login}>Login</Button>
                {/* <Button className="loginButton" onClick={test}>test</Button> */}
        </>
    )
}


export default LoginForm