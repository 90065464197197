// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#avanti {
    background: #F4BC16;
    color: black;
    width: 50%;
    border-color: #F4BC16;
}

#footerbtn{
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}`, "",{"version":3,"sources":["webpack://./src/components__old/footerButton/footerButton.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,4BAA4B;IAC5B,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC","sourcesContent":["#avanti {\n    background: #F4BC16;\n    color: black;\n    width: 50%;\n    border-color: #F4BC16;\n}\n\n#footerbtn{\n    padding: 10px 20px 10px 20px;\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
