// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.RangeSliderDiv{
    background-color: white;
    padding: 20px;
    width: 90%;
    border-radius: 12px;
}
.CompleteButtons{
    background-color: whitesmoke;
    padding: 20px;
    border-radius: 20px;
    width: 100%;
}
.date{
    min-width: 50px;
}
.card-header{
    background-color: #D2E0E3 !important;
}
#labelDate {
    background-color: #FCF0CC;
    border-radius: 12px;
    width: 100%;
}
#labelDate input{
    background-color: transparent;
    border: none;
    text-align: center;
    border-radius: 15px;
    height: 35px;
}
.orarioCard{
    display: flex;
    border-radius: 0px 0px 12px 12px;
    padding: 5%;
    background-color: #D2E0E3 !important;
    align-items: center;
    flex-direction: column;
  
}

@media only screen and (max-width: 767px) {
    .CompleteButtons{
        min-width: 220px;
        max-width: 310px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1050px){
    .CompleteButtons{
        min-width: 320px;
        max-width: 420px;
    }
}
@media only screen and (min-width: 1050px) {
    .CompleteButtons{
        min-width: 420px;
        max-width: 525px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/CardConsegna/CardConsegna.css"],"names":[],"mappings":";AACA;IACI,uBAAuB;IACvB,aAAa;IACb,UAAU;IACV,mBAAmB;AACvB;AACA;IACI,4BAA4B;IAC5B,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,eAAe;AACnB;AACA;IACI,oCAAoC;AACxC;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,aAAa;IACb,gCAAgC;IAChC,WAAW;IACX,oCAAoC;IACpC,mBAAmB;IACnB,sBAAsB;;AAE1B;;AAEA;IACI;QACI,gBAAgB;QAChB,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,gBAAgB;IACpB;AACJ;AACA;IACI;QACI,gBAAgB;QAChB,gBAAgB;IACpB;AACJ","sourcesContent":["\n.RangeSliderDiv{\n    background-color: white;\n    padding: 20px;\n    width: 90%;\n    border-radius: 12px;\n}\n.CompleteButtons{\n    background-color: whitesmoke;\n    padding: 20px;\n    border-radius: 20px;\n    width: 100%;\n}\n.date{\n    min-width: 50px;\n}\n.card-header{\n    background-color: #D2E0E3 !important;\n}\n#labelDate {\n    background-color: #FCF0CC;\n    border-radius: 12px;\n    width: 100%;\n}\n#labelDate input{\n    background-color: transparent;\n    border: none;\n    text-align: center;\n    border-radius: 15px;\n    height: 35px;\n}\n.orarioCard{\n    display: flex;\n    border-radius: 0px 0px 12px 12px;\n    padding: 5%;\n    background-color: #D2E0E3 !important;\n    align-items: center;\n    flex-direction: column;\n  \n}\n\n@media only screen and (max-width: 767px) {\n    .CompleteButtons{\n        min-width: 220px;\n        max-width: 310px;\n    }\n}\n\n@media only screen and (min-width: 768px) and (max-width: 1050px){\n    .CompleteButtons{\n        min-width: 320px;\n        max-width: 420px;\n    }\n}\n@media only screen and (min-width: 1050px) {\n    .CompleteButtons{\n        min-width: 420px;\n        max-width: 525px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
