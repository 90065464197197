
import {createSlice} from '@reduxjs/toolkit'


const init={
    "select":0,
    "authorized": true,
    "result": "SUCCESS",
    "message": {
        "categories": [
            {
                "id": 2,
                "name": "box",
                "label": "Scatole e Pacchi",
                "icon": "icons/scatola/Scatole e pacchi.svg",
                "priority": 0,
                "products": [
                    {
                        "id": 16,
                        "codename": "box_0-5_30x30x30",
                        "label": "Pacco da 0 a 5kg e/o fino a 30x30x30",
                        "price": "3.50",
                        "icon": "icons/scatola/0/5kg fino a 30x30.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 2
                    },
                    {
                        "id": 17,
                        "codename": "box_0-10_50x50x50",
                        "label": "Pacco da 0 a 10kg e/o fino a 50x50x50",
                        "price": "5.50",
                        "icon": "icons/scatola/5/10kg fino a 40x40.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 2
                    },
                    {
                        "id": 18,
                        "codename": "box_0-20_60x60x60",
                        "label": "Pacco da 0 a 20kg e/o fino a 60x60x60",
                        "price": "8.00",
                        "icon": "icons/scatola/10/20kg fino a 60x60.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 2
                    },
                    {
                        "id": 36,
                        "codename": "box_0-20_20x80x200",
                        "label": "Pacco da 0 a 20kg e/o fino a 20x80x200",
                        "price": "15.00",
                        "icon": "icons/scatola/10/20kg fino a 60x60.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "flowers",
                "label": "Fiori e piante",
                "icon": "icons/piante/Piante e fiori.svg",
                "priority": 0,
                "products": [
                    {
                        "id": 1,
                        "codename": "bouquet",
                        "label": "Bouquet",
                        "price": "3.50",
                        "icon": "icons/piante/Composizione fiori.svg",
                        "priority": 1,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    },
                    {
                        "id": 2,
                        "codename": "orchidea",
                        "label": "Fiori/Orchidea con vaso",
                        "price": "5.50",
                        "icon": "icons/piante/Orchidea.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    },
                    {
                        "id": 3,
                        "codename": "composizione",
                        "label": "Composizione floreale",
                        "price": "5.50",
                        "icon": "icons/piante/Composizione fiori.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    },
                    {
                        "id": 19,
                        "codename": "mazzo_grande",
                        "label": "Mazzo grande",
                        "price": "5.50",
                        "icon": "icons/piante/mazzo.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    },
                    {
                        "id": 20,
                        "codename": "centrotavola",
                        "label": "Centrotavola",
                        "price": "3.50",
                        "icon": "icons/piante/pinate e fiori con vaso.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    },
                    {
                        "id": 21,
                        "codename": "coroncina_laurea",
                        "label": "Coroncina laurea",
                        "price": "3.50",
                        "icon": "icons/piante/Coroncina.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    },
                    {
                        "id": 22,
                        "codename": "pianta_vaso_h40-80",
                        "label": "Pianta con vaso h 40-80",
                        "price": "5.50",
                        "icon": "icons/piante/piante  con vaso 80 100.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    },
                    {
                        "id": 23,
                        "codename": "pianta_vaso_h60-100",
                        "label": "Pianta con vaso h 60-100",
                        "price": "8.00",
                        "icon": "icons/piante/piante  con vaso 100 150.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    },
                    {
                        "id": 24,
                        "codename": "pianta_vaso_h80-150",
                        "label": "Pianta con vaso h 80-150",
                        "price": "15.00",
                        "icon": "icons/piante/piante grande con vaso.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    },
                    {
                        "id": 25,
                        "codename": "corona_funebre",
                        "label": "Corona funebre",
                        "price": "8.00",
                        "icon": "icons/piante/Corona funebre.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    },
                    {
                        "id": 26,
                        "codename": "copribara_funebre",
                        "label": "Copribara funebre",
                        "price": "15.00",
                        "icon": "icons/piante/copribarre funebre.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    },
                    {
                        "id": 27,
                        "codename": "cuscino_funebre",
                        "label": "Cuscino funebre",
                        "price": "8.00",
                        "icon": "icons/piante/cuscino funebre.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "sweets",
                "label": "Pasticceria e Forno",
                "icon": "icons/pasticceria/Pasticceria e Gelati.svg",
                "priority": 0,
                "products": [
                    {
                        "id": 4,
                        "codename": "polibox",
                        "label": "Polibox",
                        "price": "8.00",
                        "icon": "icons/pasticceria/Polibox.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 4
                    },
                    {
                        "id": 6,
                        "codename": "scatola_30_40",
                        "label": "Scatola dolce/salato fino a 30x40",
                        "price": "3.50",
                        "icon": "icons/pasticceria/scatola 30.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 4
                    },
                    {
                        "id": 28,
                        "codename": "confezione_torta_30x30",
                        "label": "Confezione Torta fino a 30x30",
                        "price": "3.50",
                        "icon": "icons/pasticceria/Torta maxi.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 4
                    },
                    {
                        "id": 29,
                        "codename": "confezione_torta_50x50",
                        "label": "Confezione Torta fino a 50x50",
                        "price": "5.50",
                        "icon": "icons/pasticceria/Torta maxi.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 4
                    },
                    {
                        "id": 30,
                        "codename": "confezione_torta_80x80",
                        "label": "Confezione Torta fino a 80x80",
                        "price": "8.00",
                        "icon": "icons/pasticceria/Torta maxi.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 4
                    },
                    {
                        "id": 31,
                        "codename": "confezione_torta_120x120",
                        "label": "Confezione Torta fino a 120x120",
                        "price": "15.00",
                        "icon": "icons/pasticceria/Torta ripiani.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 4
                    },
                    {
                        "id": 32,
                        "codename": "vassoio_dolci_salatini",
                        "label": "Vassoio dolci e salatini",
                        "price": "3.50",
                        "icon": "icons/pasticceria/Catering.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 4
                    },
                    {
                        "id": 33,
                        "codename": "barella",
                        "label": "Barella",
                        "price": "5.50",
                        "icon": "icons/pasticceria/Barelle.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 4
                    },
                    {
                        "id": 34,
                        "codename": "scatola_40_60",
                        "label": "Scatola dolce/salato fino a 40x60",
                        "price": "5.50",
                        "icon": "icons/pasticceria/scatola 49.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 4
                    }
                ]
            },
            {
                "id": 6,
                "name": "bags",
                "label": "Sacchetti e Shopper",
                "icon": "icons/sacchetti/Sacchetti e shopper.svg",
                "priority": 0,
                "products": [
                    {
                        "id": 7,
                        "codename": "sacchetto_piccolo",
                        "label": "Formato piccolo",
                        "price": "3.50",
                        "icon": "icons/sacchetti/Sacchetti Small.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 6
                    },
                    {
                        "id": 8,
                        "codename": "sacchetto_grande",
                        "label": "Formato grande",
                        "price": "5.50",
                        "icon": "icons/sacchetti/Sacchetti Large.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 6
                    }
                ]
            },
            {
                "id": 7,
                "name": "drinks",
                "label": "Vini e Bevande",
                "icon": "icons/bevande/Vini e bottiglie.svg",
                "priority": 0,
                "products": [
                    {
                        "id": 9,
                        "codename": "bottiglia",
                        "label": "Bottiglia singola",
                        "price": "3.50",
                        "icon": "icons/bevande/bottigli.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 7
                    },
                    {
                        "id": 10,
                        "codename": "box_bottiglie_2-3",
                        "label": "Confezione 2/3 bottiglie",
                        "price": "5.50",
                        "icon": "icons/bevande/Confezione x2.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 7
                    },
                    {
                        "id": 11,
                        "codename": "box_bottiglie_4-6",
                        "label": "Confezione 4/6 bottiglie",
                        "price": "8.00",
                        "icon": "icons/bevande/Confezione x3.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 7
                    },
                    {
                        "id": 35,
                        "codename": "magnum",
                        "label": "Magnum",
                        "price": "5.50",
                        "icon": "icons/bevande/Magnum.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "books",
                "label": "Libri e Riviste",
                "icon": "icons/libri/Libri e rivista.svg",
                "priority": 0,
                "products": [
                    {
                        "id": 12,
                        "codename": "book_normale",
                        "label": "Formato Normale",
                        "price": "3.50",
                        "icon": "icons/libri/Normale.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 8
                    },
                    {
                        "id": 13,
                        "codename": "book_grande",
                        "label": "Formato Grande",
                        "price": "5.50",
                        "icon": "icons/libri/Grande.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 8
                    }
                ]
            },
            {
                "id": 10,
                "name": "envelope",
                "label": "Buste e Documenti",
                "icon": "icons/busta/Buste e documenti.svg",
                "priority": 0,
                "products": [
                    {
                        "id": 14,
                        "codename": "envelop_normale",
                        "label": "Busta A4",
                        "price": "3.50",
                        "icon": "icons/busta/Busta A4.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 10
                    },
                    {
                        "id": 15,
                        "codename": "envelop_grande",
                        "label": "Busta A3",
                        "price": "3.50",
                        "icon": "icons/busta/Busta Grande.svg",
                        "priority": 0,
                        "height": null,
                        "length": null,
                        "width": null,
                        "weight": null,
                        "description": "",
                        "metadata": "{}",
                        "category": 10
                    }
                ]
            }
        ],
        "caps": [
            {
                "id": 1,
                "cap": "20121",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 2,
                "cap": "20122",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 3,
                "cap": "20123",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 4,
                "cap": "20124",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 5,
                "cap": "20125",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 6,
                "cap": "20126",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 7,
                "cap": "20129",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 8,
                "cap": "20131",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 9,
                "cap": "20133",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 10,
                "cap": "20135",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 11,
                "cap": "20136",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 12,
                "cap": "20137",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 13,
                "cap": "20143",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 14,
                "cap": "20144",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 15,
                "cap": "20145",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 16,
                "cap": "20146",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 17,
                "cap": "20147",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 18,
                "cap": "20148",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 19,
                "cap": "20149",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 20,
                "cap": "20154",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 21,
                "cap": "20155",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 22,
                "cap": "20158",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 23,
                "cap": "20159",
                "location": "centrale",
                "price": "0.00"
            },
            {
                "id": 24,
                "cap": "20127",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 25,
                "cap": "20128",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 26,
                "cap": "20132",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 27,
                "cap": "20134",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 28,
                "cap": "20138",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 29,
                "cap": "20139",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 30,
                "cap": "20141",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 31,
                "cap": "20142",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 32,
                "cap": "20152",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 33,
                "cap": "20153",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 34,
                "cap": "20156",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 35,
                "cap": "20157",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 36,
                "cap": "20161",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 37,
                "cap": "20162",
                "location": "esterno",
                "price": "2.00"
            },
            {
                "id": 38,
                "cap": "20151",
                "location": "esterno",
                "price": "2.00"
            }
        ],
        "extraprices": [
            {
                "id": 1,
                "extra": "extratime",
                "label": "Fuori orario",
                "price": "2.00"
            },
            {
                "id": 2,
                "extra": "express",
                "label": "Express 2h",
                "price": "2.00"
            },
            {
                "id": 3,
                "extra": "holiday",
                "label": "Festivo",
                "price": "2.00"
            },
            {
                "id": 4,
                "extra": "extracap",
                "label": "External CAP",
                "price": "2.50"
            },
            {
                "id": 5,
                "extra": "ped_zone",
                "label": "Pedestrian Zone",
                "price": "2.50"
            }
        ]
    }
}
export const FormSlice = createSlice({
    name:"form",
    initialState:init,
    reducers:{
        hydrate:(state, action) => {
            // do not do state = action.payload it will not update the store
            return action.payload
            },
        updateForm:(state, action)=>{
            state=action.payload
        },
        selectCategory:(state,action)=>{
            state.select=action.payload
        }

        // EditProdotto :(state, action)=>{
        //     state.prenotazione.categoria.prodotti[action.payload.index]=action.payload.data
        // }
    },
   
})

export const { updateForm,selectCategory} = FormSlice.actions

export default FormSlice.reducer
