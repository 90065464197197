

import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import * as url from '../../backend.json'
import OrderList from "../../components/OrderList/OrderList";
import DivSpace from "../../components/DivSpace/DivSpace";
import Menu from "../../components/menu/menu";
import axios from "axios";
import { Spinner , Card} from "react-bootstrap";

const BaseURL= url;
function HistoryPage({type}){

    const [order_resp, setOrderResp]=useState(undefined)
    const [showloading, setloading]=useState(true)
    useEffect(()=>{
        console.log("type")
        console.log("order list")
        console.log(BaseURL.orderAPI_response)
        axios.get(BaseURL.API+"orders/?limit=2&state="+type, {withCredentials:true}).then(e=>{
            console.log(e)
            setOrderResp(e.data)
            setloading(false)
        }).catch(err=>{
            console.log(err)
        })
        setOrderResp(BaseURL.orderAPI_response)
    }, [])
    return (
        <div className="AccountPage">
            <Menu></Menu>
        {  !showloading ? 
        <>
        <DivSpace distancepx={"100px"}></DivSpace>
            <h1>{type}</h1>
        {
            order_resp==undefined ? "non c'è nulla":
            <OrderList payload={order_resp}></OrderList>
        }</> : <div className=" SpinnerPage">
               
        <Spinner  animation="border" variant="warning" />
        <h1>Loading... </h1>
        </div>
        }
        </div>
    )
}

export default HistoryPage