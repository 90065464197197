import {createSlice} from '@reduxjs/toolkit'






// const consegna={
//     info:{},
//     categoria:{},
//     prodotto:{}
// }

// const prenotazione={
//     ritiro:{},
//     consegne:[]
// }

// const init={
//     carello:{
//        prenotazioni:[]
//     }

// } 


const init={
    info:{},
    data:{
      data:"",
      h:""
    }


}


export const SingleRitiro = createSlice({
    name:"carello",
    initialState:init,
    reducers:{


      updateInfoRitiro: (state, action)=>{
          console.log("updateinfo")
          state.info[action.payload.key]=action.payload.value
      },
      updateDateInfoRitiro:(state, action)=>{

  
        state.data[action.payload.key]=action.payload.value
        
        // state.info.h=tmph
        
      },
        clearStateRitiro:(state, action)=>{

            state=init
        },

    },
   
})

export const { updateInfoRitiro, updateDateInfoRitiro ,clearStateRitiro} = SingleRitiro.actions

export default SingleRitiro.reducer
