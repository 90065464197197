
import { Button } from 'react-bootstrap'
import './PrenotaHeader.css'
import { useState } from 'react'

function PrenotaHeader(){

    const [selection, setSelection]= useState(0)


    function Select(idButton){
        setSelection(idButton)
    }

    return (
        <div>
        <div className='HeaderClass'>
            <div id="titleHeader">
                <Button id="exitButton">X</Button>
                <h2>Prenota</h2>
            </div>

        </div >
                    <div className='HeaderClass' id="BodyHeader">
                        <div>
                            <Button className="ButtonHeader" 
                                style={selection==0 ? {"color":"black"}: {}} onClick={e=>{Select(0)}} >Cosa ?</Button>
                            <div id="Notification" style={selection!=0 ? {"display":"none"}: {}}></div>
                        </div>
                        <div>
                        <Button className="ButtonHeader" style={selection==1 ? {"color":"black"}: {}} onClick={e=>{Select(1)}}>Quando</Button>
                        <div id="Notification" style={selection!=1 ? {"display":"none"}: {}}></div>
                        </div>
                        <div>
                        <Button className="ButtonHeader" style={selection==2 ? {"color":"black"}: {}}onClick={e=>{Select(2)}}>Mittente</Button>
                        <div id="Notification" style={selection!=2 ? {"display":"none"}: {}}></div>
                        </div>
                        <div>
                        <Button className="ButtonHeader" style={selection==3 ? {"color":"black"}: {}} onClick={e=>{Select(3)}}>Destinatario</Button>
                        <div id="Notification" style={selection!=3 ? {"display":"none"}: {}}></div>
                        </div>
                    
                </div>
        </div>


    )
}


export default PrenotaHeader