
import './customcard.css'
import CustomButton from "../CustomButton/CustomButton";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from 'react';
function CustomCard({info}) {

  const Buttons=[
    {
      img: "bottiglie.png",
      descrizione: "Vino rosso", 
      id:0,
      w:60,
      h:60,
      mh:40,
      mw:40
      
    },
    {
      img: "fiori.png",
      descrizione: "mazzo di fiori", 
      id:1,
      w:60,
      h:60,
      mh:40,
      mw:40
      
    },
    {
      img: "torta.png",
      descrizione: "mazzo di fiori", 
      id:2,
      w:60,
      h:60,
      mh:40,
      mw:40
    },
    {
      img: "box.png",
      descrizione: "box", 
      id:3,
      w:60,
      h:60,
      mh:40,
      mw:40
    },
    {
      img: "dolcino.png",
      descrizione: "pasticceria", 
      id:4,
      w:60,
      h:60,
      mh:40,
      mw:40
    },
    {
      img: "frutta.png",
      descrizione: "vassoio di frutta", 
      id:5,
      
    },
    {
      img: "scatole.png",
      descrizione: "scatole", 
      id:6,
      w:60,
      h:60,
      mh:40,
      mw:40
    },
    {
      img: "fiori.png",
      descrizione: "mazzo di fiori", 
      id:7,
      w:60,
      h:60,mh:60
      
    },
    {
      img: "vaso_di_fiori.png",
      descrizione: "vaso di fiori", 
      id:8,
      w:60,
      h:60,      
      mh:40,
      mw:40
    },
    {
      img: "box.png",
      descrizione: "box", 
      id:9,
      w:60,
      h:60,
      mh:40,
      mw:40
    },
    {
      img: "torta.png",
      descrizione: "mazzo di fiori", 
      id:10,
      w:60,
      h:60,
      mh:40,
      mw:40
    },
    {
      img: "Vector.png",
      descrizione: "Altro", 
      id:11,
      w:60,
      h:60,
      mh:10,
      mw:40
    }
  ]

  const [state, setState]= useState(Buttons)
  const [rows, setrows]= useState([])


  useEffect(()=>{
    const rows_tmp = [];

    for (let i = 0; i < state.length; i += 3) {
      rows_tmp.push(state.slice(i, i + 3));
    }
    setrows(rows_tmp)
    console.log("use Effect")
  },[])
  
  return (
    <></>

      
  );
}

export default CustomCard;