import { Card } from "react-bootstrap"
import { Form } from "react-router-dom"
import { Checkbox, CheckboxGroup } from "rsuite"
import 'rsuite/dist/rsuite.min.css';
import './CardExtraPrice.css'
import { useDispatch } from "react-redux";
import { updateInfo, updateProdotti } from "../../store/reducers/Prenotazione/tmp_reducer";
import { useEffect, useState } from "react";
import { updateInfoRitiro } from "../../store/reducers/Prenotazione/tmp_reducer_ritiro";



function CardExtraPrice({type}){
    const dispatch=useDispatch()
    const [tmpjson, setTmp]=useState({
        "fuori orario":false,
        "express":false,
        "festivo":false,
        "cap esterno":false,
        "pedonale":false
    })
 
    useEffect(()=>{
        let tmp={
            "fuori orario":false,
            "express":false,
            "festivo":false,
            "cap esterno":false,
            "pedonale":false
        }
        setTmp(tmp)
        
    }, [type])
    function Update(label, value){
        setTmp({...tmpjson, [label]:value})
        
     
        if(type==1){
            dispatch(updateInfo({key:"extraprice", value:tmpjson}))
        }else{
            dispatch(updateInfoRitiro({key:"extraprice", value:tmpjson}))
        }
        
    }
    return (
        <Card className="CardExtraPrice">
            <Card.Body>
                <h3>Extra Prezzo</h3>
            <CheckboxGroup inline name="checkbox-group" className="CheckGroup">
                <Checkbox onCheckboxClick={e=>{Update(e.target.value,e.target.checked)}} color="yellow" value="fuori orario"> <div className="ChecboxValue"><img width={"40px"} src="/clock.png"></img> Fuori Orario</div> </Checkbox>
                <Checkbox onCheckboxClick={e=>{Update(e.target.value,e.target.checked)}}color="yellow"value="express">  <div className="ChecboxValue"><img width={"40px"} src="/express.png"></img> Express</div></Checkbox>
                <Checkbox onCheckboxClick={e=>{Update(e.target.value,e.target.checked)}}color="yellow"value="festivo"> <div className="ChecboxValue"><img width={"40px"} src="/festivo.png"></img> Festivo</div></Checkbox>
                <Checkbox onCheckboxClick={e=>{Update(e.target.value,e.target.checked)}}color="yellow"value="cap esterno"> <div className="ChecboxValue"> <img width={"40px"} src="/capesterno.png"></img>CAP Esterno</div></Checkbox>
                <Checkbox onCheckboxClick={e=>{Update(e.target.value,e.target.checked)}}color="yellow"value="pedonale">  <div className="ChecboxValue"><img width={"40px"} src="/delivery-man.png"></img> Zona Pedolane</div></Checkbox>
            </CheckboxGroup>
            </Card.Body>
        </Card>
    )
}

export default CardExtraPrice