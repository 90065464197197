// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.colBtn{
    align-items: center;
    display: flex;
    gap:10px;
    
}

.btnCarello{
    color: black;
        background-color: #F4BC16;
        border: none;
        
        border-radius: 12px;
    
}

#carelloContainer .row .col{
    text-align: start;
}

.ExtraInfo{
    font-size: small;
    font-weight: 300;
}

.InfoConsegna{
    overflow: hidden;

    border-top: solid 1px darkgray;
}

@media only screen and (max-width: 767px) {
    #carelloContainer .row{
        margin: 0px;
    }
    .CardDiv{
      width: 100%;
      padding-left: 0%;
      padding-right: 0%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1050px){
    .CardDiv{
      width: 100%;
      padding-left: 0%;
      padding-right: 0%;
    }
  }
  @media only screen and (min-width: 1050px) {
    .CardDiv{
      width: 80%;
      padding-left: 15%;
      padding-right: 15%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Carrello/Carello.css"],"names":[],"mappings":";;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,QAAQ;;AAEZ;;AAEA;IACI,YAAY;QACR,yBAAyB;QACzB,YAAY;;QAEZ,mBAAmB;;AAE3B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;;IAEhB,8BAA8B;AAClC;;AAEA;IACI;QACI,WAAW;IACf;IACA;MACE,WAAW;MACX,gBAAgB;MAChB,iBAAiB;IACnB;EACF;EACA;IACE;MACE,WAAW;MACX,gBAAgB;MAChB,iBAAiB;IACnB;EACF;EACA;IACE;MACE,UAAU;MACV,iBAAiB;MACjB,kBAAkB;IACpB;EACF","sourcesContent":["\n\n.colBtn{\n    align-items: center;\n    display: flex;\n    gap:10px;\n    \n}\n\n.btnCarello{\n    color: black;\n        background-color: #F4BC16;\n        border: none;\n        \n        border-radius: 12px;\n    \n}\n\n#carelloContainer .row .col{\n    text-align: start;\n}\n\n.ExtraInfo{\n    font-size: small;\n    font-weight: 300;\n}\n\n.InfoConsegna{\n    overflow: hidden;\n\n    border-top: solid 1px darkgray;\n}\n\n@media only screen and (max-width: 767px) {\n    #carelloContainer .row{\n        margin: 0px;\n    }\n    .CardDiv{\n      width: 100%;\n      padding-left: 0%;\n      padding-right: 0%;\n    }\n  }\n  @media only screen and (min-width: 768px) and (max-width: 1050px){\n    .CardDiv{\n      width: 100%;\n      padding-left: 0%;\n      padding-right: 0%;\n    }\n  }\n  @media only screen and (min-width: 1050px) {\n    .CardDiv{\n      width: 80%;\n      padding-left: 15%;\n      padding-right: 15%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
