// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Privacy{
    display: flex;
    gap: 10px;
    padding: 20px;
}

.link{
    color: #F4BC16;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/LoginCard/RegistrationForm/RegistrationForm.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":["\n\n.Privacy{\n    display: flex;\n    gap: 10px;\n    padding: 20px;\n}\n\n.link{\n    color: #F4BC16;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
