

import { Button, Col, Row, Container } from "react-bootstrap" 
import './footerButton.css'
function FooterButton(){



    return (
        <div>
        <div id="footerbtn">
    
              <Button id="indietro" variant="outline-secondary">Indietro</Button>
              <Button id="avanti" color="F4BC16">Avanti</Button>
   

            
        </div>
        </div>
    )
}

export default FooterButton