import {useState, useEffect, useRef} from 'react'
import './RitiroCard.css'
import { Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
// import 'rsuite/Slider/styles/index.css';
// import 'rsuite/RangeSlider/styles/index.css';
// import 'rsuite/InputGroup/styles/index.css';
// import 'rsuite/InputNumber/styles/index.css';
import { Button, Card, } from 'react-bootstrap'
import DivSpace from '../DivSpace/DivSpace';
import DatePicker from "react-datepicker";
import {  Slider } from 'rsuite';
import { useDispatch } from 'react-redux';
import { updateDateInfoRitiro } from '../../store/reducers/Prenotazione/tmp_reducer_ritiro';
function RitiroCard({UpdateState}){
    const dispatch= useDispatch()
    const labels = ['07:00', '07:30', '08:00', '08:30',
     '09:00', '09:30', '10:00', 
     '10:30', '11:00', '11:30',
     '12:00', '12:30', '13:00',
     '13:30', '14:00', '14:30',
     '15:00', '15:30', '16:00',
     '16:30', '17:00', '17:30',
     '18:00', '18:30', '19:00'
  
  ];
    const [value, setValue] = useState(0);
    const [minSlider, setMin]=useState(0);
    const [startDate, setStartDate] = useState(new Date());
    
    const [datebtn, setdatebtn]=useState([
        false,false,false
    ])

      
    useEffect(()=>{
      let date= new Date()
  
     
      let hours= date.getHours();
      console.log("hours", hours)
      const FindH = (element) => element == `${hours<10 ? "0"+hours : hours}:00`;
      let index=labels.findIndex(FindH)
      setMin(index+3)
      setValue(index+3)
      let m=date.getMonth()+1
     let d= date.getDate()
      let tmpinf=`${date.getUTCFullYear()}/${m<10 ? "0"+m: m}/${d<10? "0"+d:d}`
      dispatch(updateDateInfoRitiro({key:"data", value:tmpinf}))
      dispatch(updateDateInfoRitiro({key:"h", value:labels[index+3]}))
    }, [])
    function UpdateDate(info, id){
        console.log("update date")
        let date= new Date();
        if(id!=undefined){
            let tmp_btn=[false,false,false]
            tmp_btn[id]=true
            setdatebtn(tmp_btn)
        }
     
        if(info=="oggi"){
          let date2= new Date()
          let hours= date2.getHours();
          console.log("hours", hours)
          const FindH = (element) => element == `${hours<10 ? "0"+hours : hours}:00`;
          let index=labels.findIndex(FindH)
          console.log(index)
          setValue(index+2)
          setMin(index+2)
            let m=date.getMonth()+1
            let d= date.getDate()
            info=`${date.getUTCFullYear()}/${m<10 ? "0"+m: m}/${d<10? "0"+d:d}`
        }
        if(info=="domani"){
            setMin(0)
            console.log(date)
            date= date.setDate(date.getDate()+1)
            console.log(date)
            date= new Date(date)
            let m=date.getMonth()+1
            let d= date.getDate()
            info=`${date.getUTCFullYear()}/${m<10 ? "0"+m: m}/${d<10? "0"+d:d}`
        }
        dispatch(updateDateInfoRitiro({key:"data", value:info}))
        UpdateState.data["data"]=info
        console.log(info)
        console.log(UpdateState)
    }


    function updateDatapicker(info){
       
        let date= new Date(info)
        let d= date.getDate()
        let m= date.getMonth()+1
        setStartDate(info)
        UpdateDate(`${date.getUTCFullYear()}/${m<9 ? "0"+m: m}/${d<9? "0"+d:d}`, 2)
    }
    function UpdateHours(info){
        dispatch(updateDateInfoRitiro({key:"h", value:info}))
        UpdateState.data["h"]=info
        // UpdateState_btn(id)
        // console.log(UpdateState)
    }
    // function UpdateExpress(info){
        
    //     UpdateState.data["express"]=info
    //     console.log(UpdateState)
    // }


    

    return (
        <Card>
        <Card.Header id="cardHeader" className="ritiroHeader">
            <div className="headerDiv" >
          
              <h3>Ritiro</h3>


                
            </div>
            <div className="extraButton">
            </div>
        </Card.Header>
        <Card.Body className="BodyCard orarioCard">
            <div className="CompleteButtons">
                <div className="divCardsButtons">
                <Button active={datebtn[0]}  className='ButtonCards oggi' onClick={e=>{UpdateDate("oggi", 0)}}>Oggi</Button>
                <Button active={datebtn[1]}  className='ButtonCards domani' onClick={e=>{UpdateDate("domani", 1)}}>Domani</Button>
                {/* <Button className='ButtonCards dataBtn' onClick={e=>{UpdateDate("data")}}>Data</Button> */}
                <label className={datebtn[2] ? "ActiveLabel": "deactiveLabel"}  id="labelDate"><DatePicker dateFormat="dd/MM/YYYY"   selected={startDate} onChange={(date) => updateDatapicker(date)} /></label>


                </div>
                <div className="CheckboxDiv">
                <Form>
                {/* {['checkbox'].map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                    <Form.Check // prettier-ignore
                        type={type}
                        id={`default-${type}`}
                        label={`con consegna altro giorno`}
                        onChange={e=>{UpdateExpress(e.target.checked)}}
                    />


                    </div>
                ))} */}
            
                </Form>
                </div>
                
            </div>
            <DivSpace distancepx={"20px"}></DivSpace>
            <div className='CompleteButtons'>
                <Container id="containerOrari">
                    <Row>
                      <h3>Il ritiro è pronto dalle: <strong>{labels[value]}</strong></h3>
                    <Row>
                      <DivSpace></DivSpace>
                    
        <Slider
          progress
          graduated
          min={minSlider}
          defaultValue={0}
          max={labels.length - 1}
          // handleTitle={labels[value]}
          tooltip={false}
          value={value}
          onChange={value => {
            UpdateHours(labels[value])
            setValue(value);
          }}
        />
     
      </Row>
      <DivSpace></DivSpace>

                        {/* <Col>
                        <Button active={activatebtn[0]} className='ButtonCards orario'  onClick={e=>{UpdateHours("07:00-09:00", 0)}}>07:00-09:00</Button>
                        </Col>
                        <Col>
                        <Button active={activatebtn[1]} className='ButtonCards orario' onClick={e=>{UpdateHours("09:00-11:00",1)}}>09:00-11:00</Button>
                        </Col>
                    
                        <Col>
                        <Button active={activatebtn[2]} className='ButtonCards orario' onClick={e=>{UpdateHours("11:00-13:00",2)}}>11:00-13:00</Button>
                        </Col>
                        <Col>
                        <Button active={activatebtn[3]} className='ButtonCards orario'onClick={e=>{UpdateHours("13:00-15:00",3)}}>13:00-15:00</Button>
                        </Col>
                   
                        <Col>
                        <Button active={activatebtn[4]} className='ButtonCards orario'onClick={e=>{UpdateHours("15:00-17:00",4)}}>15:00-17:00</Button>
                        </Col>
                        <Col>
                        <Button active={activatebtn[5]} className='ButtonCards orario'onClick={e=>{UpdateHours("17:00-19:00",5)}}>17:00-19:00</Button>
                        </Col>
                   
                        <Col>
                        <Button active={activatebtn[6]} className='ButtonCards orario'onClick={e=>{UpdateHours("19:00-21:00",6)}}>19:00-21:00</Button>
                        </Col>
                        <Col>
                        <Button active={activatebtn[7]} className='ButtonCards orario'onClick={e=>{UpdateHours("09:00-18:00",7)}}>09:00-18:00</Button>
                        </Col> */}
                    </Row>
                </Container>
            </div>





        </Card.Body>
      </Card>
    )
}



export default RitiroCard