
import {useState, useEffect} from 'react'
import './DynamicCard.css'
import { Container, Row, Col } from "react-bootstrap";

import { Button, Card } from 'react-bootstrap'
import CustomButton from '../../components__old/CustomButton/CustomButton'
import { useSelector, useDispatch } from 'react-redux';
import { selectCategory } from '../../store/reducers/Form/reducer';
import { updateCategoria, updateProdotti } from '../../store/reducers/Prenotazione/tmp_reducer';
function ColloCard({props, Buttons, type, statepage, update, cat, prod, selectCat}){
  const dispatch= useDispatch()
  const form =useSelector(state=>state["form"])
  const prenotazioneSingle= useSelector(state=>state["prenotazione"])
  const [state, setState]= useState(Buttons)
  const [rows, setrows]= useState([])
  const [number_collo, setNumber]=useState(1)
  const [product, setProduct]=useState({
    name:""
  })
  const [name, setName]=useState("")
  function select(index){
    console.log("select")
    let tmp= Buttons;
    // tmp[index].activate=true;
    tmp.map((item,i)=>{
      index==i ? item.activate=true : item.activate=false
    })
    setState(tmp)
    let tmp_result=product
    tmp_result.name=tmp[index].descrizione;
    setNumber(1)
    setName(tmp[index].descrizione)
    setProduct(tmp_result)
    prod("n", 1)
    dispatch(updateProdotti({key:"n", value:1}))
    if(type=="Collo"){
      // let tmpstate= statepage["categoria"]
      dispatch(updateCategoria({key:"name", value:tmp[index].descrizione}))
      cat("name", tmp[index].descrizione)
      dispatch(selectCategory(index))
      // update("categoria",tmpstate )
      // statepage.categoria.name=name
    }
    else{
      // let tmpstate= statepage["prodotti"]
      console.log(number_collo)
      dispatch(updateProdotti({key:"price", value:tmp[index].extrainfo?.price}))
      dispatch(updateProdotti({key:"name", value:tmp[index].descrizione}))
      prod("price", tmp[index].extrainfo?.price)
      setTimeout(()=>{
        prod("name", tmp[index].descrizione)
      },100)
      // update("prodotti",tmp[index].descrizione )
      // statepage.prodotti.name=name
    }
    
    // product.name=tmp[index].descrizione
    // console.log(tmp)
  }

  useEffect(()=>{
    
    setName("")
    setState(Buttons)
    setNumber(1)
  }, [Buttons])
  function quantity(mode){
    let tmp= number_collo
    
    mode ? tmp=tmp+1 : tmp=tmp-1
    setNumber(tmp)
    console.log(tmp)
    prod("n",tmp)
    dispatch(updateProdotti({key:"n", value:tmp}))
    if(type!="Collo"){
      let tmpstate= product["prodotti"]
      
     
      // statepage.prodotti.number=number
    }

    // console.log(result)
  }
  useEffect(()=>{
    const rows_tmp = [];

    for (let i = 0; i < state.length; i += 3) {
      rows_tmp.push(state.slice(i, i + 3));
    }
    setrows(rows_tmp)
    console.log("use Effect")
  },[])
    return (
        <Card>
           {type==undefined || type=="prodotto" ?<Card.Header id="cardHeader">
          <><div className="headerDiv">
            <img width={"16px"} src="/fiori.png"></img>
              <p>{name}</p>


                <Button className="headerButton" onClick={e=>{quantity(false)}}> <div>- </div></Button>
                {number_collo}
                <Button className="headerButton" onClick={e=>{quantity(true)}}> <div>+ </div> </Button>
            </div>
            {/* <div className="extraButton">
            <Button className="extraButtonStyle"> <div><img width={20} src="/copy.png"></img> </div></Button>
              <Button className="extraButtonStyle"> <div> <img width={20} src="/trash.png"></img> </div> </Button>
            </div>  */}
            </>
        </Card.Header>: <></>}
        <Card.Body>
          <h3>{type=="Collo" ? "Tipologia di collo": ""}</h3>
        <Container id="containerButton" className={type=="Collo"? "ColloContainer": ""} style={{alignItems:"center", paddingTop:"5%"}}>
        {/* {rows.map((row, index) => ( */}
        <Row  className="rowPadding">
          {state.map((buttonData, buttonIndex) => (
            <Col key={buttonIndex}>
              <CustomButton type={"collo"} index={buttonIndex} info={buttonData} activationfunction={select}></CustomButton>
            </Col>
          ))}
        </Row>
         {/* ))} */}
        </Container>
        </Card.Body>
      </Card>
    )
}

export default ColloCard