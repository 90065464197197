import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'rsuite';



function StepsPage({position}){
    const navigate=useNavigate()
    function MoveTo(path){
        navigate(path)
    }

    useEffect(()=>{
        console.log("position steps",position)
        
    }, [position])
    
    return (
        <Steps current={position} small>
        <Steps.Item onClick={e=>{MoveTo("/")}} title="Ritiro"  />
        <Steps.Item onClick={e=>{MoveTo("/ordine/")}} title="Consegne" />
        <Steps.Item onClick={e=>{MoveTo("/ordine/riepilogo")}} title="Riepilogo" />
        <Steps.Item onClick={e=>{MoveTo("/ordine/riepilogo/carello")}} title="Carello" />
      </Steps>
    )
}

export default StepsPage