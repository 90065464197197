import { Card } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-router-dom"
import { Checkbox, CheckboxGroup } from "rsuite"
import 'rsuite/dist/rsuite.min.css';
import { updateInfo, updateProdotti } from "../../store/reducers/Prenotazione/tmp_reducer";
import { useState, useEffect } from "react";




function CardTransportoDetails(){
    const state= useSelector(state=>state["prenotazione"])
    const dispatch=useDispatch()
    const [tmpjson, setTmp]=useState({
        fragile:false,
        temperatura:false,
        appendere:false
    })

    

    function Update(label, value){
        setTmp({...tmpjson, [label]:value})
        dispatch(updateInfo({key:"trasportoPrice", value:tmpjson}))
    }

    return (
        <Card className="CardExtraPrice">
            <Card.Body>
                <h3>Dettagli di Transporto</h3>
            <CheckboxGroup inline name="checkbox-group" className="CheckGroup">
                <Checkbox onCheckboxClick={e=>{Update(e.target.value,e.target.checked)}}color="yellow" value="fragile"> <div className="ChecboxValue"><img width={"40px"} src="/fragile.png"></img> Fragile</div> </Checkbox>
                <Checkbox onCheckboxClick={e=>{Update(e.target.value,e.target.checked)}}color="yellow"value="temperatura">  <div className="ChecboxValue"><img width={"40px"} src="/temperatura.png"></img> Controllo Temperatura</div></Checkbox>
                <Checkbox onCheckboxClick={e=>{Update(e.target.value,e.target.checked)}}color="yellow"value="appendere"> <div className="ChecboxValue"><img width={"40px"} src="/appendere.png"></img> Da appendere</div></Checkbox>
                
            </CheckboxGroup>
            </Card.Body>
        </Card>
    )
}

export default CardTransportoDetails