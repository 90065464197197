import {createSlice} from '@reduxjs/toolkit'



const init={
    account:{
        payload:{
            "id": "",
            "username": "",
            "email": "",
            "first_name": "",
            "last_name": "",
            "phone": "",
            "company": "",
            "CF": "",
            "pec": "",
            "PI": "",
            "code": "",
            "account_state": "",
            "previous_login": "",
            "metadata": {},
            "bill_addr_via": "",
            "bill_addr_n_civ": "",
            "bill_addr_local": "",
            "bill_addr_cap": "",
            "bill_addr_city": "",
            "bill_addr_prov": "",
            "bill_addr_country": ""
        }

    },
    activate:false,
    update:false

} 

export const AccountSlice = createSlice({
    name:"user",
    initialState:init,
    reducers:{
        updateAccount: (state, action)=> {
            // let payload= JSON.parse(action.payload)
            // console.log(payload, "redux Account")
            state.account=action
            state.update=true;
        },
        activateAccount:(state)=>{
            state.activate=true
        }
    },
   
})

export const { updateAccount , activateAccount} = AccountSlice.actions

export default AccountSlice.reducer
