import {createSlice} from '@reduxjs/toolkit'
import { act } from 'react'






// const consegna={
//     info:{},
//     categoria:{},
//     prodotto:{}
// }

// const prenotazione={
//     ritiro:{},
//     consegne:[]
// }

// const init={
//     carello:{
//        prenotazioni:[]
//     }

// } 


const init={    
    orders:[]
}


export const OrderSlice = createSlice({
    name:"order",
    initialState:init,
    reducers:{
        AddOrder: (state, action)=> {
            console.log("redux", action.payload)
            // let payload= JSON.parse(action.payload)
            // console.log(payload, "redux Account")
            state.orders=action.payload
        },
        clear:(state)=>{

          state=init
        }

        // EditProdotto :(state, action)=>{
        //     state.prenotazione.categoria.prodotti[action.payload.index]=action.payload.data
        // }
    },
   
})

export const { AddOrder} = OrderSlice.actions

export default OrderSlice.reducer
