// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*{
  --bs-btn-hover-bg:#F4BC16 !important;
  --bs-btn-active-bg:#F4BC16 !important
}

*::-webkit-scrollbar {
  display: none;}
  *{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
  }
.rs-steps-item-content{
  text-align: start !important;
}
.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  background-color: #F4BC16 !important;
  border-color: #F4BC16 !important;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper{
  border-color: #F4BC16 !important;
  color: #F4BC16 !important;
}
.rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title:after{
  border-color: #F4BC16 !important;
}

h1{
  font-size: 27px !important;
}


h3{
  font-size: 18px !important;;
}

h4{
  font-size: 27px !important;;
}


h5{
  font-size: 27px !important;;
}


p{
  font-size: 14px !important;;

}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;;AAGA;EACE,oCAAoC;EACpC;AACF;;AAEA;EACE,aAAa,CAAC;EACd;IACE,wBAAwB,GAAG,gBAAgB;IAC3C,qBAAqB;EACvB;AACF;EACE,4BAA4B;AAC9B;AACA;EACE,oCAAoC;EACpC,gCAAgC;AAClC;AACA;EACE,gCAAgC;EAChC,yBAAyB;AAC3B;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE,0BAA0B;AAC5B;;;AAGA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;;AAGA;EACE,0BAA0B;AAC5B;;;AAGA;EACE,0BAA0B;;AAE5B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n*{\n  --bs-btn-hover-bg:#F4BC16 !important;\n  --bs-btn-active-bg:#F4BC16 !important\n}\n\n*::-webkit-scrollbar {\n  display: none;}\n  *{\n    -ms-overflow-style: none;  /* IE and Edge */\n    scrollbar-width: none;\n  }\n.rs-steps-item-content{\n  text-align: start !important;\n}\n.rs-steps-item-status-process .rs-steps-item-icon-wrapper {\n  background-color: #F4BC16 !important;\n  border-color: #F4BC16 !important;\n}\n.rs-steps-item-status-finish .rs-steps-item-icon-wrapper{\n  border-color: #F4BC16 !important;\n  color: #F4BC16 !important;\n}\n.rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title:after{\n  border-color: #F4BC16 !important;\n}\n\nh1{\n  font-size: 27px !important;\n}\n\n\nh3{\n  font-size: 18px !important;;\n}\n\nh4{\n  font-size: 27px !important;;\n}\n\n\nh5{\n  font-size: 27px !important;;\n}\n\n\np{\n  font-size: 14px !important;;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
