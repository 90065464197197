// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .img {
      aspect-ratio: 0.65;
      object-fit: auto;
      object-position: center;
      width: 100%;
      fill: var(--main-color-Yellow-500, #fcf0cc);
      max-width: 361px;
    }

    #divCustomCard{
      height: 556px ;
      width: 361px ;
      background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/8918f36e875c67efe52e905ba8524b353159deea056535247feafba3c6b08655?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&');
      /* background-size: contain; */
      background-repeat: no-repeat;
      /* width: 100%; */
      /* height: 0; */
      /* display: flex; */

      /* padding-top: 66.64%; */
    }


#info{
  background-color: #fcf0cc;
  border-radius: 50%;
  width:30px ;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translateX(160px);

}

#mainContainer{
  display: flex;
  justify-content: center;
}


.rowPadding{
  padding-top: 2%;
}`, "",{"version":3,"sources":["webpack://./src/components__old/customcard/customcard.css"],"names":[],"mappings":";IACI;MACE,kBAAkB;MAClB,gBAAgB;MAChB,uBAAuB;MACvB,WAAW;MACX,2CAA2C;MAC3C,gBAAgB;IAClB;;IAEA;MACE,cAAc;MACd,aAAa;MACb,kLAAkL;MAClL,8BAA8B;MAC9B,4BAA4B;MAC5B,iBAAiB;MACjB,eAAe;MACf,mBAAmB;;MAEnB,yBAAyB;IAC3B;;;AAGJ;EACE,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,4BAA4B;;AAE9B;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;;AAGA;EACE,eAAe;AACjB","sourcesContent":["\n    .img {\n      aspect-ratio: 0.65;\n      object-fit: auto;\n      object-position: center;\n      width: 100%;\n      fill: var(--main-color-Yellow-500, #fcf0cc);\n      max-width: 361px;\n    }\n\n    #divCustomCard{\n      height: 556px ;\n      width: 361px ;\n      background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/8918f36e875c67efe52e905ba8524b353159deea056535247feafba3c6b08655?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&');\n      /* background-size: contain; */\n      background-repeat: no-repeat;\n      /* width: 100%; */\n      /* height: 0; */\n      /* display: flex; */\n\n      /* padding-top: 66.64%; */\n    }\n\n\n#info{\n  background-color: #fcf0cc;\n  border-radius: 50%;\n  width:30px ;\n  height: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  transform: translateX(160px);\n\n}\n\n#mainContainer{\n  display: flex;\n  justify-content: center;\n}\n\n\n.rowPadding{\n  padding-top: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
