import { Button, Card,Col,Container, Form, Row } from "react-bootstrap";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import './CardMittenteDest.css'
import { useEffect,useState, useRef} from "react";
import Autocomplete from "react-google-autocomplete";
import { usePlacesWidget } from "react-google-autocomplete";
import { Input } from "rsuite";
import { updateGeneralInfo, updateInfo } from "../../store/reducers/Prenotazione/tmp_reducer";
import { updateInfoRitiro } from "../../store/reducers/Prenotazione/tmp_reducer_ritiro";
const YOUR_GOOGLE_MAPS_API_KEY="AIzaSyBJOD-ayksuoVKyJawYHMIKkJsHIKgTDYk"

// import google from 'googleapis'

function CardMittenteDestinatario({ type, info, UpdateState, update, indexPrenotazione}){
    const prenotazione= useSelector(state=>state["prenotazione"])
    const dispatch= useDispatch()
    const { ref:refgoogle, autocompleteRef: autoRef } = usePlacesWidget({
        apiKey:YOUR_GOOGLE_MAPS_API_KEY,
     
       
        onPlaceSelected:(place, inputRef, autocomplete) => {
            console.log(autocomplete);
          }
      });
    const carellostate= useSelector(state=>state["carello"])
    const [formGrid, setForm]=useState([])
    const [lastInfo, setLast]=useState([])
    
    function UpdateDataMittente(key, value){

            UpdateState.info[key]=value


        // console.log(key, UpdateState)
    }  
    const [googleref, setgoogle]=useState(null)

    const inputRef = useRef(null);


    
      useEffect(() => {
        console.log(autoRef)
        console.log(refgoogle.current)
        if (inputRef.current && refgoogle.current) {
          inputRef.current = refgoogle.current;
        }
      }, [autoRef]);

    //   setTimeout(()=>{
        
    //     setgoogle(refgoogle)
    //     console.log(googleref)
    // }, 4000)
    // let loadgoogle=setInterval(()=>{
    //     console.log(refgoogle)
    //     if(refgoogle.current != null){
    //         setgoogle(refgoogle)
    //         clearInterval(loadgoogle)
    //     }


    // }, 1000)

   


    useEffect(()=>{

        // setgoogle(refgoogle)
       
        // while(refgoogle.current == null){
        //     console.log(refgoogle.current)
        // }
        console.log(info)
        let row1=[info?.input[0], info?.input[1]]
        let row2= [info?.input[2]]
        let row3= [info?.input[3]]
        let row4= [info?.input[4], info?.input[5]]
        let row5= [info?.input[6]]
        console.log(row1)
        setForm([row1, row2,row3,row4,row5, ])
        if(indexPrenotazione != undefined){


            if(carellostate.carello.prenotazioni[indexPrenotazione]!= undefined){

            
     
            console.log(indexPrenotazione, carellostate)

            console.log(carellostate.carello.prenotazioni[indexPrenotazione])
            
            if(carellostate.carello.prenotazioni[indexPrenotazione].consegne.length>0){
            let tmp_info=carellostate.carello.prenotazioni[indexPrenotazione].consegne[carellostate.carello.prenotazioni[indexPrenotazione].consegne.length-1].info
            console.log(tmp_info)
            setLast([])
            setLast([tmp_info])
            update("indirizzo destinatario", tmp_info["indirizzo destinatario"])
            update("Nome e Cognome", tmp_info["Nome e Cognome"])
            update("Nome",tmp_info["Nome e Cognome"] )
            update("telefono", tmp_info["telefono"])
            update("Email", tmp_info["Email"])
            console.log("test bello", lastInfo)
        }
    }
    }
    }, [indexPrenotazione])

  
    function SelectAddress(index){
        let tmp_info=carellostate.carello.prenotazioni[indexPrenotazione].consegne[index].info
        console.log(tmp_info)
        setLast([])
        setLast([tmp_info])
        let tmpdata= prenotazione.info?.data
        let tmph= prenotazione.info?.h
        console.log(tmpdata, tmph)
        
        dispatch(updateGeneralInfo(tmp_info))
        dispatch(updateInfo({key:"data", value:tmpdata}))
        dispatch(updateInfo({key:"h", value:tmph}))
        update("Insegne Negozio o Nome-Numero cit destinatario", tmp_info["Insegne Negozio o Nome-Numero cit destinatario"]!=undefined? tmp_info["Insegne Negozio o Nome-Numero cit destinatario"]: "")
        update("Indirizzo Destinatario", tmp_info["Indirizzo Destinatario"]!=undefined? tmp_info["Indirizzo Destinatario"]: "")
        update("Nome", tmp_info["Nome"]!=undefined? tmp_info["Nome"]: "")
        update("Cognome", tmp_info["Cognome"]!=undefined? tmp_info["Cognome"]: "")
        // update("Nome",tmp_info["Nome e Cognome"] !=undefined? tmp_info["indirizzo destinatario"]: "")
        update("Telefono", tmp_info["Telefono"]!=undefined? tmp_info["telefono"]: "")
        update("Email", tmp_info["Email"]!=undefined? tmp_info["Email"]: "")
    }
  
    function updateValue(label, value){
        if(type==0){
            console.log(label, value)
            dispatch(updateInfoRitiro({key:label, value:value}))
            // dispatch(updateInfo({key:label, value:value.formatted_address}))
            // update(label, value.formatted_address)
        }else{
            dispatch(updateInfo({key:label, value:value}))
            
        }
        
    }


    return (
        <Card className="CardStyleMittente CardStyle">
            <Card.Header className="headerCardStyle">
                <h3>{info?.title}</h3>
            </Card.Header>
            <Card.Body className="FormStyle">
                  {
                    // <div className="containerForm">
                    <Container className="containerForm">
                    <Row>
                   <Col xs={2} className="SelectCons">
                 
            
                    {indexPrenotazione!=undefined && carellostate.carello.prenotazioni[indexPrenotazione]!= undefined &&
                    carellostate.carello.prenotazioni[indexPrenotazione].consegne.length>0 ?
                    carellostate.carello.prenotazioni[indexPrenotazione].consegne.map((item, index)=>(
                        <Button className="SelectAddress" onClick={e=>{SelectAddress(index)}}>Cons. {index}</Button>
                    ))
     
                    : <></>
                    }

                   </Col>
                   <Col xs={indexPrenotazione==undefined ? 12:8}>
                  
              {formGrid?.map((item, indexrow)=>(
                    
                    <Row key={indexrow}>
                        {item.map((subitem, index)=>(
                            <Col key={index}>
                             <Form.Label  className="labelStyle">{subitem?.label}</Form.Label>
                    {subitem?.ref!=undefined ? <div className=""><Autocomplete 
                        apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                        
                        options={ {
                            
                            types: ['geocode', 'establishment']
                        }}
                        className="FormControl googleInput form-control"
                        placeholder={prenotazione.info["extrainfoAddress"]!=undefined ? prenotazione.info["extrainfoAddress"].formatted_address:"Inserisci posizione"}
                        // defaultValue={prenotazione.info["extrainfoAddress"]!=undefined ? prenotazione.info["extrainfoAddress"]:null}
                        onPlaceSelected={(place) => {
                          console.log(place);
                          update("extrainfoAddress", place)
                          dispatch(updateInfoRitiro({key:"Indirizzo mittente", value:place.formatted_address}))
                          dispatch(updateInfoRitiro({key:"extrainfoAddress", value:place}))
                          dispatch(updateInfo({key:"extrainfoAddress", value:place}))
                          
                        }}
                    ></Autocomplete></div>:
                                        <Form.Control className="FormControl"
                                        type={subitem?.type}
                                        defaultValue={prenotazione.info[subitem?.label]!=undefined && type==1 ? prenotazione.info[subitem?.label]:null}
                                        
                                        id={subitem?.ida}
                                        key={subitem?.label}
                                        // defaultValue={lastInfo.length>0 ? lastInfo[0][subitem?.label]: ""}
                                        onChange={e=>{ updateValue(subitem?.label, e.target.value)}}
                                       
                                    />
                    }

                  

                  
                            </Col>
                        ))}
                   
                    </Row>
                ))}
                </Col>
                </Row>
                {/* </div> */}
                </Container>
                }
            </Card.Body>
        </Card>
    )

}

export default CardMittenteDestinatario