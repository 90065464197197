import CardInfoRiepilogo from "../../components/RiepilogoCard/CardInfoRiepilogo/CardInfoRiepilogo"
import CarelloCard from "../../components/RiepilogoCard/RiepilogoCard"
import DivSpace from "../../components/DivSpace/DivSpace"
import Menu from "../../components/menu/menu"
import {Row, Col, Button} from 'react-bootstrap'
import { UseSelector, useSelector } from "react-redux"
import { useState, useEffect } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import './Riepilogo.css'
import CardFooterNext from "../../components/CardFooter_gonext/CardFooter_gonext"
import StepsPage from "../../components/StepsPage/StepsPage"




function RiepilogoPages(){
    const carelloState= useSelector((state) => state["carello"])
    const [dest, setDest]=useState([])
    const [selectedIndex, setSelected]=useState(1)
    const [information, setInformation]=useState({
        title:"indirizzo destinatario",
        cards:[]
    })
    const [priceinfo, setPriceInfo]=useState({})

    
    let infosinglecard={
        title:"indirizzo mittente",
        data:[
            <p>nome cognome</p>,
            <p>via .</p>,
            <p>CAP 00000</p>,
            <p>tel 0000000</p>,
            <div>Nota:
                <p>hfassjakda</p>
            </div>,
            
        ]
    }
    let infosinglecardDest=[{
        title:"indirizzo destinatario",
        data:[
            <p>nome cognome</p>,
            <p>via .</p>,
            <p>CAP 00000</p>,
            <p>tel 0000000</p>,
            <div>Nota:
                <p>sadkalsad</p>
            </div>,
        ]
    }]
    // const [spese, setspeceForm]=useState({
    //     title:<div className="spese">
    //         <div className="titlespese">
    //             <p >Ordine Numero</p>
    //             <p className="Value">000</p>
    //         </div>
    //         <div className="Dataspese">
    //             <p>Prezzo</p>
    //             <p className="Value">€{ "00"}</p>
    //         </div>
    //     </div>,
    //     data:[
    //         <div className="spese bodyspese"><p>Aggiuntivi</p>
    //             <p>Prezzo</p>
    //             </div>,
    //         <div className="spese bodyspese infospese"><p>consegna zona pedonali</p>
    //         <p>€00.0</p>
    //         </div>,
    //         <div className="spese bodyspese infospese"><p>consegna express entro 2ore dalla presa</p>
    //         <p>€00.0</p>
    //         </div>,
    //         <div className="spese bodyspese infospese"><p>Iva 22%</p>
    //         <p>€{"00"}</p>
    //         </div>,
    //     ]
    // })


    function RenderRiepilogo(index){

        let prenotazione=carelloState.carello.prenotazioni[index]
        console.log("riepilogo",prenotazione)
        if(prenotazione == undefined){
            return null
        }
        infosinglecard.data=[
            <p>{prenotazione.ritiro.info["Nome"]} {prenotazione.ritiro.info["Cognome"]}</p>,
            <p>{prenotazione.ritiro.info["extrainfoAddress"]!=undefined ? prenotazione.ritiro.info["extrainfoAddress"].formatted_address: "//"}</p>,
            <p>CAP {prenotazione.ritiro.info["extrainfoAddress"]!=undefined ?prenotazione.ritiro.info.extrainfoAddress.address_components[prenotazione.ritiro.info.extrainfoAddress.address_components.length-1].long_name :"//"}</p>,
            <p>tel {prenotazione.ritiro.info.telefono}</p>,
            <div><strong>Nota:</strong>
            <p>{prenotazione.ritiro.info.Nota}</p>
        </div>


        
        ]
        let pricejson={}
        pricejson["idOrder"]=index
        let tmp_dest=[]
        let tmpprice=0
        let extra=0
        prenotazione.consegne.map((item, index)=>{
            console.log(item)
            let numberofprodoct=item.prodotti.n!=undefined ? item.prodotti.n: 1
            extra= extra+(item.info["zona pedonale"] ? 2.0 : 0.0)
            tmpprice=tmpprice+parseFloat(item.prodotti.price)*numberofprodoct
            let infodest={
                title:`Indirizzo Destinazione ${index+1}`,
                data:[
                    <p>{item.info["Nome"]} {item.info["Cognome"]}</p>,
                    <p>{item.info.extrainfoAddress!=undefined ?item.info.extrainfoAddress.formatted_address: "//"}</p>,
                    <p>CAP {item.info.extrainfoAddress!=undefined ?item.info.extrainfoAddress.address_components[item.info.extrainfoAddress.address_components.length-1].long_name : "//"}</p>,
                    <p>tel {item.info.Telefono}</p>,
                    <div><strong>Nota:</strong>
                    <p>{item.info.Nota}</p>
                    </div>
                ]
            }
            tmp_dest.push(infodest)
           
        })

        pricejson["price"]=tmpprice
        pricejson["extra"]=extra
        pricejson["iva"]=tmpprice*22/100
        // setPriceInfo(pricejson)
        let spese={
            title:<div className="spese">
                <div className="titlespese">
                    <p >Ordine Numero</p>
                    <p className="Value">000{pricejson["idOrder"]+1}</p>
                </div>
                <div className="Dataspese">
                    <p>Prezzo</p>
                    <p className="Value">€{pricejson["price"]!=undefined ?pricejson["price"].toFixed(2): "00"}</p>
                </div>
            </div>,
            data:[
                <div className="spese bodyspese"><p>Aggiuntivi</p>
                    <p>Prezzo</p>
                    </div>,
                <div className="spese bodyspese infospese"><p>consegna zona pedonali</p>
                <p>€{pricejson["extra"].toFixed(2)}</p>
                </div>,
                <div className="spese bodyspese infospese"><p>consegna express entro 2ore dalla presa</p>
                <p>€00.0</p>
                </div>,
                <div className="spese bodyspese infospese"><p>Iva 22%</p>
                <p>€{pricejson["iva"]}</p>
                </div>,
            ]
        }
        const total={
            title:"",
        data:[
            <div className="spese bodyspese tot">
        
                <p >Totale</p>
                <p className="Value">€{pricejson["iva"]+pricejson["price"]+pricejson["extra"]}</p>
            
        </div>
        ]
        }
        // setspeceForm(tmpSpeseform)


        return {
            title:"",
            cards:[
                <CardInfoRiepilogo info={infosinglecard} ></CardInfoRiepilogo>,
                tmp_dest.map((item,index)=>(
                    <>
                    <CardInfoRiepilogo info={item}></CardInfoRiepilogo>
                    <DivSpace distancepx={"10px"}></DivSpace>
                    </>
                )),
                <DivSpace distancepx={"10px"}></DivSpace>,
                <CardInfoRiepilogo info={spese} ></CardInfoRiepilogo>,
                <DivSpace distancepx={"10px"}></DivSpace>,
                <CardInfoRiepilogo info={total} ></CardInfoRiepilogo>
                
            ]
        }

    }
    useEffect(()=>{
        setInformation(RenderRiepilogo(0))
        setSelected(1)
    },[])
    
    function showInfo(index){
        setInformation(RenderRiepilogo(index-1))
        setSelected(index)
    }

    
    return(
        <>

        <div className="App">
        <CardFooterNext step={2}></CardFooterNext>
            <Menu></Menu>
        <div className="CardDiv riepilogoDIV">
        <DivSpace distancepx={"70px"}></DivSpace>
            <StepsPage position={2}></StepsPage>
        </div>
        <DivSpace distancepx={"20px"}></DivSpace>
            <div className="titlePage CardDiv">
            
            <h3>Riepilogo Ordine</h3>
                        
            <DropdownButton
                align="end"
                title={`Ordine ${selectedIndex}   `}
                id="dropdown-menu-align-end"
                onSelect={e=>{showInfo(e)}}
                >
            {carelloState.carello.prenotazioni.map((item, i)=>(
                <Dropdown.Item eventKey={i+1}>Ordine {i+1}</Dropdown.Item>
            ))}
            </DropdownButton>
            </div>
            <div className="CardDiv riepilogoDIV">
           
            <DivSpace distancepx={"10px"}></DivSpace>
            
            <CarelloCard infoCard={information}></CarelloCard>
            </div>
            

        </div>
        </>
    )
}


export default RiepilogoPages