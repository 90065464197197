import * as React from "react";
import './navbar.css'
function NavigationBottomBar() {
  const menuItems = [
    {
      icon: "track.png",
      label: "Traccia",
    },
    {
      icon: "carello.png",
      label: "Carrello",
    },
  ];

  return (
    <>
      <div className="container">
        <div className="logo" />
        <div className="menu">
          <img color="black" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ba8c58a7a4b5ad3b767a0d957045851e694c08905541e79293b0871db2fb452?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&" alt="" className="menu-background" />
          <div className="menu-content">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/ebf906d6ab72514b40321e17eca67f77d13c8f6cf69526d05a706f8b1417d7de?apiKey=c51da5dbfe2f475d8d1183b45ebf761f&" alt="" className="profile-image" />
            <div className="profile-section">
              <img src="ordini.png" alt="" className="profile-icon" />
              <div className="profile-label">Ordini</div>
            </div>
          </div>
          <div className="menu-items">
            {menuItems.map((item, index) => (
              <MenuItem key={index} icon={item.icon} label={item.label} />
            ))}
          </div>
        </div>
      </div>

    </>
  );
}

function MenuItem({ icon, label }) {
  return (
    <div className="menu-item">
      <img src={icon} alt="" className="menu-item-icon" />
      <div className="menu-item-label">{label}</div>
    </div>
  );
}


export default NavigationBottomBar