import { Button} from "react-bootstrap";
import './CustomButton.css'

function CustomButton({info, index, activationfunction, type}){



    return(
        <Button active={info.activate} id="custombutton" className={type=="collo" ? "collobtn":""} onClick={e=>{activationfunction(index)}}>
            <div id="buttonDiv"> <div style={{height: info.h, width:info.w, display: "flex", justifyContent:"center", alignItems:"center"}} >
            <img style={{height:info.mh, width:info.mw}}src={"/"+info.img}></img>
            </div>
                    <p style={{color:"black"}}>{info.descrizione}</p>
            </div>
        </Button>
    )
}


export default CustomButton