import {useState, useEffect} from 'react'
import './CardConsegna.css'

import { Card } from 'react-bootstrap'
import DivSpace from '../DivSpace/DivSpace';
import DatePicker from "react-datepicker";
import { RangeSlider } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { updateInfo } from '../../store/reducers/Prenotazione/tmp_reducer';

function CardConsegna({statePage, update}){

    const prenotazione = useSelector(state=>state["prenotazione"])
    const dispatch=useDispatch()
    const labels = ['07:00', '07:30', '08:00', '08:30',
     '09:00', '09:30', '10:00', 
     '10:30', '11:00', '11:30',
     '12:00', '12:30', '13:00',
     '13:30', '14:00', '14:30',
     '15:00', '15:30', '16:00',
     '16:30', '17:00', '17:30',
     '18:00', '18:30', '19:00'
  
  ];
    const [value, setValue] = useState([0,0]);
    const [minSlider, setMin]=useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [activatebtn, setActivate]=useState([
        false,false, false,false, false,false, false,false,false
    ])
    useEffect(()=>{
        let date=new Date()
        let d= date.getDate()
        let m= date.getMonth()+1
        UpdateDate(`${date.getUTCFullYear()}/${m<9 ? "0"+m: m}/${d<9? "0"+d:d}`, 2)
    },[])
    useEffect(()=>{
        console.log("state")
    }, [statePage])
    function UpdateState(key, value, id){
       

        update(key, value)
        let tmp=[false,false, false,false, false,false, false,false,false] 
        tmp[id]=true;
        setActivate(tmp)
        console.log(activatebtn)  
    }

    function UpdateDate(info){
        let date= new Date(info)
        let d= date.getDate()
        let m= date.getMonth()+1
        m+=1;
        setStartDate(info)
        // UpdateDate(`${date.getUTCFullYear()}/${m<9 ? "0"+m: m}/${d<9? "0"+d:d}`, 2)

        console.log("update date")
        dispatch(updateInfo({key:"data", value:`${date.getUTCFullYear()}/${m<9 ? "0"+m: m}/${d<9? "0"+d:d}`}))
        update("data", `${date.getUTCFullYear()}/${m<9 ? "0"+m: m}/${d<9? "0"+d:d}`)
    }

    function UpdateHours(info){
        update("h",info)
        dispatch(updateInfo({key:"h", value:info}))
        // UpdateState_btn(id)
        // console.log(UpdateState)
    }
    return (
        <Card>
        <Card.Header id="cardHeader" className="ritiroHeader">
            <div className="headerDiv" >
          
            Sceglia la data di consegna

            </div>
            <div className="extraButton">
            </div>
        </Card.Header>
        <Card.Body className="BodyCard orarioCard">
            
            <div className=" CompleteButtons">

            {/* <Button className='ButtonCards' onClick={e=>{UpdateState("data", "data",0)}}>Data</Button> */}
            <label id="labelDate"><DatePicker dateFormat="dd/MM/YYYY"   className='ButtonCards' selected={startDate} onChange={(date) => UpdateDate(date)} /></label>

            </div>
            <DivSpace distancepx={"20px"}></DivSpace>
            
            <div className='CompleteButtons'>
            <div>
                      <h3>Fascia orari di consegna, dalle <strong>{labels[value[0]]}</strong> alle  <strong>{labels[value[1]]}</strong></h3>
                    </div>
            <RangeSlider
          progress
          graduated
          min={0}
          step={1}
          defaultValue={0}
          max={labels.length - 1}
          // handleTitle={labels[value]}
          tooltip={false}
          value={value}
          onChange={value => {
            if(value[1]-value[0]>3){
                UpdateHours(labels[value[0]]+"-"+labels[value[1]])
                setValue(value);
            }
            
          }}
        />
                {/* <Container id="containerOrari">
                Scegli l'ora di consegna
                    <Row>
                        <Col>
                        <Button active={activatebtn[1]} className='ButtonCards orario'  onClick={e=>{UpdateState("h", "07:00-09:00",1)}}>07:00-09:00</Button>
                        </Col>
                        <Col>
                        <Button active={activatebtn[2]}  className='ButtonCards orario' onClick={e=>{UpdateState("h", "09:00-11:00",2)}}>09:00-11:00</Button>
                        </Col>
                    
                        <Col>
                        <Button active={activatebtn[3]}  className='ButtonCards orario' onClick={e=>{UpdateState("h", "11:00-13:00",3)}}>11:00-13:00</Button>
                        </Col>
                        <Col>
                        <Button  active={activatebtn[4]} className='ButtonCards orario' onClick={e=>{UpdateState("h", "13:00-15:00",4)}}>13:00-15:00</Button>
                        </Col>
                   
                        <Col>
                        <Button  active={activatebtn[5]} className='ButtonCards orario' onClick={e=>{UpdateState("h", "15:00-17:00",5)}}>15:00-17:00</Button>
                        </Col>
                        <Col>
                        <Button active={activatebtn[6]}  className='ButtonCards orario' onClick={e=>{UpdateState("h", "17:00-19:00",6)}}>17:00-19:00</Button>
                        </Col>
                  
                        <Col>
                        <Button active={activatebtn[7]}  className='ButtonCards orario' onClick={e=>{UpdateState("h", "19:00-21:00",7)}}>19:00-21:00</Button>
                        </Col>
                        <Col>
                        <Button active={activatebtn[8]} className='ButtonCards orario' onClick={e=>{UpdateState("h", "09:00-18:00",8)}}>09:00-18:00</Button>
                        </Col>

                    </Row>
                </Container> */}
            </div>





        </Card.Body>
      </Card>
    )
}


export default CardConsegna