

import { Button, Card, Form,  } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useState } from "react";
import axios from 'axios'
import * as url from '../../../backend.json'

import Swal from "sweetalert2";
const BaseURL= url



function ResetForm({token}){


    const [username_, setusername]=useState("")
    const [resp_code, setRespCode]=useState(false)
    const [respMsg, setrespMsg]=useState("")
    const [resetJson, setLogin]=useState({
        email:"",
    })
    const [newPsw, setPSW]=useState({
        password:"",
        confirm_password:""
    })
    function update(key, value){
        resetJson[key]=value
        
    }
    function updatePSW(key, value){
        newPsw[key]=value
    }
    function Reset(){
        console.log("reset")
        axios.post(BaseURL.API+"accounts/password_reset/",resetJson, {withCredentials:true}).then(e=>{
            Swal.fire({
                text: "Reset PsW avvenuta, si prega di controllare l'eamil",
                heightAuto: false,
                icon: "success"
              })
        }).catch(err=>{
            Swal.fire({
                text: "Errore durante la fase di reset",
                heightAuto: false,
                icon: "error"
              })
        })
        // console.log(login)
       
    }


    function sendNewPsw(){
        console.log(newPsw.password, newPsw.confirm_password)
        if(newPsw.password!="" && newPsw.confirm_password!=""){
            if(newPsw.password==newPsw.confirm_password){
                axios.post(BaseURL.API+`accounts/password_reset/confirm/?token=${token}`,{"token":token, "password": newPsw.password}, {withCredentials:true}).then(e=>{
                    console.log(e)
                    setRespCode(false)
                    window.location.href="/login"
                }).catch(err=>{
                    setRespCode(true)
                    setrespMsg(err)
                    console.error(err)
                })
            }
            else{
                console.warn("psw diverse")
            }
        }else{
            console.warn("psw empty")
        }
    }
    return (
        <>
        {token==null || token=="" ?
        <>
        <h1>Reset Password</h1>
            <FloatingLabel
            controlId="floatingTextarea_psw"
            label="email"
            className="mb-3"
        >
            <Form.Control type="textarea" placeholder="example@email.com" onChange={e=>{update("email", e.target.value)}} />
        </FloatingLabel>

                <Button className="loginButton" onClick={e=>{Reset()}}>Reset</Button>
        </> : 
        
        <>
         <h1>Confirm Password</h1>
            <FloatingLabel
            controlId="floatingTextarea_psw"
            label="password"
            className="mb-3"
        >
            <Form.Control type="password"  isInvalid={resp_code} placeholder="password" onChange={e=>{updatePSW("password", e.target.value)}} />
        </FloatingLabel>
        <FloatingLabel
            controlId="password"
            label="confirm password"
            className="mb-3"
        >
            <Form.Control isInvalid={resp_code} type="password" placeholder="password" onChange={e=>{updatePSW("confirm_password", e.target.value)}} />
            <Form.Control.Feedback type="invalid">
            {respMsg?.message}
          </Form.Control.Feedback>
        </FloatingLabel>

                <Button className="loginButton" onClick={e=>{sendNewPsw()}}>Reset</Button>
        </>}
        </>
    )
}

export default ResetForm