import {configureStore} from '@reduxjs/toolkit'


import AccountSlice from './reducers/Account/reducer'
import  PrenotazioneSlice  from './reducers/Prenotazione/reducer'
import  FormSlice from './reducers/Form/reducer'
import  SinglePrenotazioneSlice from './reducers/Prenotazione/tmp_reducer'
import SingleRitiro  from './reducers/Prenotazione/tmp_reducer_ritiro'
import  OrderSlice  from './reducers/Prenotazione/order'


export const store= configureStore({
    reducer:{
        carello: PrenotazioneSlice,
        user :AccountSlice,
        form: FormSlice,
        prenotazione:SinglePrenotazioneSlice,
        ritiro:SingleRitiro,
        order:OrderSlice
       
    },  
})




 

