import { useEffect, useState } from "react"
import { Card, Container, Row, Col } from "react-bootstrap"
import DivSpace from "../DivSpace/DivSpace"
import './OrderList.css'




function OrderList({payload}){
    const [info, setInfo]=useState(undefined)
    const [show, setShow]=useState(undefined)

    useEffect(()=>{
        setInfo(payload)
    }, [payload])

    function showInfo(index){
        if(index==show){
            setShow(undefined)
        }else{
            setShow(index)
        }
        
      

    }

    return (
        <div>
            
            {payload!=undefined   ? 
            payload.message.map((item, index)=>(
                <>
                {console.log(index)}
                
                <Card className="" onClick={e=>{showInfo(index)}}>
                    <Card.Body>
                        <div className="TitleList">
                            <h3>Ordine {index+1}</h3>
                            <h3>{item.order.state}</h3>
                        </div>
                       
                        <Container className="OrderListContainer">
                            <Row>
                                <Col>
                                <p>Prezzo: {item.order.price}</p>

                                </Col>
                                <Col>
                                <p>{item.order.transaction_method}</p>
                                </Col>
                                <Col>
                                <p>{item.order.transaction_result}</p>
                                </Col>
                            </Row>
                            {show==index ?
                            <>
                            <Row>
                                <p>Data creazione:  <strong>{item.order.created_at}</strong></p>
                                
                            </Row>
                            <Row className="IdRow">
                                {item.order.id}
                            </Row>
                            </>
                            : <></>}
                        </Container>
                    </Card.Body>
                </Card>
                <DivSpace></DivSpace>
                </>
            )):<> nulla da vedere</>
        }
        </div>
    )
}


export default OrderList