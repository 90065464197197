import {createSlice} from '@reduxjs/toolkit'






// const consegna={
//     info:{},
//     categoria:{},
//     prodotto:{}
// }

// const prenotazione={
//     ritiro:{},
//     consegne:[]
// }

// const init={
//     carello:{
//        prenotazioni:[]
//     }

// } 


const init={
    info:{
      data:"",
      h:""
    },
    categoria:{},
    prodotti:{},

}


export const SinglePrenotazioneSlice = createSlice({
    name:"carello",
    initialState:init,
    reducers:{

        AddConsegna2: (state, action)=> {
            // let payload= JSON.parse(action.payload)
            // console.log(payload, "redux Account")
            // const index = state.prenotazione.findIndex(object => {
            //     return object.id === action.payload.id;
            // });
            state.prenotazioni.consegne.push(action.payload.data);           
        },
        updateInfo: (state, action)=>{
          console.log("updateinfo")
          state.info[action.payload.key]=action.payload.value
      },
      updateGeneralInfo:(state, action)=>{

  
        state.info=action.payload
        
        // state.info.h=tmph
        

        
      },
      updateCategoria: (state, action)=>{
        state.categoria[action.payload.key]=action.payload.value
    },
    updateProdotti: (state, action)=>{
      state.prodotti[action.payload.key]=action.payload.value
  },
        clearState:(state, action)=>{

            state=init
        },

    },
   
})

export const { updateInfo, updateCategoria ,updateGeneralInfo, updateProdotti,clearState} = SinglePrenotazioneSlice.actions

export default SinglePrenotazioneSlice.reducer
