import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "react-datepicker/dist/react-datepicker.css";
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LoginPage from './pages/Login/Login';

import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import {store}  from './store/store.js'
import ConsegnaPage from './pages/Mittente/ConsegnaPage';
import { Provider } from 'react-redux';
import AccountPage from './pages/Account/Account';
import RiepilogoPages from './pages/Riepilogo/Riepilogo';
import CarelloPage from './pages/Carrello/Carello';
import { hydrate } from './store/reducers/Prenotazione/reducer';
import PagamentoPage from './pages/Pagamento/Pagamento';
import Menu from './components/menu/menu';
import HistoryPage from './pages/History/History.js';



const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <App></App>
    ),
  },
  {
    path: "/account",
    element: (
      <AccountPage></AccountPage>
    ),
  },
  {
    path: "/cronologia/final",
    element: (
      <HistoryPage type={"final"}></HistoryPage>
    ),
  },
  {
    path: "/cronologia/submitted",
    element: (
      <HistoryPage type={"submitted"}></HistoryPage>
    ),
  },
  {
    path: "/cronologia/progress",
    element: (
      <HistoryPage type={"progess"}></HistoryPage>
    ),
  },
  {
    path: "/ordine/",
    element: (
      <ConsegnaPage></ConsegnaPage>
    ),
  },
  ,
  {
    path: "/ordine/riepilogo",
    element: (
     <RiepilogoPages></RiepilogoPages>
    ),
  },
  {
    path: "/ordine/riepilogo/carello",
    element: (
     <CarelloPage></CarelloPage>
    ),
  },
  {
    path: "/ordine/riepilogo/carello/pagamento",
    element: (
     <PagamentoPage></PagamentoPage>
    ),
  },
  {
    path: "login",
    element: (
    <LoginPage></LoginPage>
    ),
  },
  {
    path: "carrello",
    element: <p></p>,
  }
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
store.subscribe(()=>{
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
})
const getTodosFromLocalStorage = () => {
  try { 
    const persistedState = localStorage.getItem('reduxState') 
    if (persistedState) 
      return JSON.parse(persistedState)
  }
  catch (e){ 
    console.log(e)
  }
}
const getFormFromLocalStorage = () => {
  try { 
    const persistedStateForm = localStorage.getItem('reduxStateForm') 
    if (persistedStateForm) 
      return JSON.parse(persistedStateForm)
  }
  catch (e){ 
    console.log(e)
  }
}


const todos = getTodosFromLocalStorage()
if(todos){
  console.log(todos)
  store.dispatch(hydrate(todos.carello))
}
const todosForm = getFormFromLocalStorage()
if(todosForm){
  console.log(todosForm)
  store.dispatch(hydrate(todosForm))
}


root.render(


 
  <Provider store={store}>

    
    <RouterProvider router={router} />
    
    
 </Provider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
