

import {useState, useEffect, useDebugValue} from 'react'

import { UseSelector, useDispatch, useSelector } from 'react-redux'

import "./Carello.css"
import Menu from '../../components/menu/menu'
import CardFooterNext from '../../components/CardFooter_gonext/CardFooter_gonext'
import { Card, Container, Button, Col, Row } from 'react-bootstrap'
import DivSpace from '../../components/DivSpace/DivSpace'
import { RemoveConsegna } from '../../store/reducers/Prenotazione/reducer'
import StepsPage from '../../components/StepsPage/StepsPage'
function CarelloPage(){
    const dispatch = useDispatch()
    const carelloState= useSelector((state) => state["carello"])
    const [show, setShow]=useState(undefined)
    const [totalPrice, setTotalPrice]=useState([])

    useEffect(()=>{
        let tmp_show=[]
        let tmp_price=0
        let tmp_total=[]
        let product_price=0;
        carelloState.carello.prenotazioni.map((item, index)=>{
            tmp_price=0
            // tmp_price+=3.0
            product_price=0
            item.consegne.map((consegna, indexC)=>{
                console.log(consegna)
                console.log(parseFloat(consegna.prodotti.price)*consegna.prodotti.n*22/100)
                product_price+=(parseFloat(consegna.prodotti.price)*consegna.prodotti.n )
                if(consegna.info["zona pedonale"]){
                    tmp_price+=2;
                }
            })
            console.log("production price", index,product_price, product_price*22/100)
            tmp_price+=product_price+product_price*22/100
            
            tmp_total=tmp_total.concat(tmp_price)
            tmp_show.push(false)
        })
        setShow(tmp_show)
        console.log("total",tmp_total)
        setTotalPrice(tmp_total)
    }, [carelloState])


    function showInfo(index){
        if(index==show){
            setShow(undefined)
        }else{
            setShow(index)
        }
      

    }

    function Delete(index){
        console.log(index)
        dispatch(RemoveConsegna(index))
    }
    return (
        <>
         <Menu></Menu>
        <div className='App'>
        <CardFooterNext step={3}></CardFooterNext>
       
        <div className="CardDiv">
        <DivSpace distancepx="250px"></DivSpace>
        
        <StepsPage position={3}></StepsPage>
        <DivSpace distancepx={"20px"}></DivSpace>
        {carelloState.carello.prenotazioni.length>0 ? carelloState.carello.prenotazioni.map((item, index)=>(
            <>
        <Card>
        <Card.Body>
            <Container id="carelloContainer">
                <Row>
 
                    <Col>
                    Ordine #{index+1}
                   </Col>
                   <Col xs={3}>
                   Totale € {totalPrice[index]}
                   </Col>
                </Row>
                <Row>
                    <Col xl={7}>
                    x{item.consegne.length} Consegne
                    <DivSpace></DivSpace>
                    {
                        show==index ? 
                            <>
                              {item.consegne.map((item, index)=>(
                                <div className="InfoConsegna">
                                    <DivSpace distancepx={"20px"}></DivSpace>
                                <p>{item.info?.data} - {item.info?.h}</p>
                                <p className='ExtraInfo'>{item.info['indirizzo destinatario']} </p>
                                <p className='ExtraInfo'> x{item.prodotti.n} {item.prodotti.name}</p>
                                
                                </div>

                              ))}
                              
                            </>:
                            <></>
                    }
                    </Col>
                    <Col xs={5} className='colBtn'>
            
                    
                    <Button className='btnCarello' style={{backgroundColor:"white", color:"black"}} onClick={e=>{showInfo(index)}}>{index==show ? "Nascondi": "Visualizza"}</Button>
                    <Button  className='btnCarello' onClick={e=>{Delete(index)}}>Elimina</Button>
    
                    </Col>
                </Row>
            </Container>
        </Card.Body>
    </Card>
    <DivSpace ></DivSpace>
    </>
        )): 
        <div>
            <DivSpace distancepx={"-20px"}></DivSpace>
            <h1 style={{fontWeight: "bold"}}>Il tuo carrello è vuoto.</h1>
            <img src="/carellovuoto.png"></img>
        </div>
        }

        </div>
        </div>
        <DivSpace distancepx={"200px"}></DivSpace>
        </>
    )
}

export default CarelloPage