
import { Button, Card, Form, FormCheck,  } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useState } from "react";
import axios from 'axios'
import './RegistrationForm.css'
import * as url from '../../../backend.json'
import Swal from "sweetalert2";


const BaseURL= url;

function RegistrationForm(){

    const [username_, setusername]=useState("")
    const [psw, setPsw]=useState("")
    const [checkPrivacy, setCheck]=useState(false)
    const [registrationJSon, setJSON]=useState({
        email: "",
        first_name: "",
        last_name: "ì",
        phone: "",
        company: "",
        bill_addr_via: "",
        bill_addr_n_civ: "",
        bill_addr_local: "",
        bill_addr_cap: "",
        bill_addr_city: "",
        bill_addr_prov: "",
        bill_addr_country: "",
        PI: "",
        CF: "",
        code: "",
        pec: "",
        metadata:{}
    })
    function update(key, value){
        registrationJSon[key]=value
        
    }

    function updateAccept(e){
        setCheck(e)
    }
    function Registration(){
        // console.log(login)
        if(checkPrivacy){
            axios.post(BaseURL.API+"accounts/signup/",registrationJSon, {withCredentials:true}).then(e=>{
                console.log(e)
                Swal.fire({
                    text: "Registrazione avvenuta, si prega di controllare l'email",
                    heightAuto: false,
                    icon: "success"
                })
            }).catch(err=>{
                Swal.fire({
                    text: err?.response?.data?.error,
                    heightAuto: false,
                    icon: "error"
                })
                console.log(err)
            })
        }
        else{
            Swal.fire({
                text: "Accept the condiction to continue the subscription.",
                heightAuto: false,
                icon: "warning"
            })
        }
        console.log(registrationJSon);
    }

    return(
        <>
         <h1>Create Account</h1>
            <FloatingLabel
            controlId="floatingTextarea"
            label="company"
            className="mb-3"
        >
            <Form.Control as="textarea" placeholder="Busket-line" onChange={e=>{update("company", e.target.value)}} />
        </FloatingLabel>
        {Object.keys(registrationJSon).slice(0,4).map((item, index)=>(
                <>
                    <FloatingLabel

                        controlId={item}
                        label={item}
                        className="mb-3">
                    <Form.Control type="text" placeholder={item} onChange={e=>{update(item, e.target.value)}}/>

                    </FloatingLabel>
                    
                </>
        ))}
                <div className="Privacy">
                <FormCheck onChange={e=>updateAccept(e.target.checked)}></FormCheck>
                <p>Ho letto e accettato la <a className="link" target="_blank" href="https://busket-line.com/privacy-policy/">Policy Privacy</a> ed i <a className="link" target="_blank" href="https://busket-line.com/termini-e-condizioni/">Termini e condizioni</a>*</p>
                </div>
                
                <Button className="loginButton" onClick={e=>{Registration()}}>Crea</Button>
                
        </>
    )
}


export default RegistrationForm