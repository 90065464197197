// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordDiv{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    /* padding: 5%; */
}



.PasswordDiv span {
    transform:translateX(-50px)
    /* transform: translateX(140px) translateY(15px); */


}

.BtnPsw{
    background-color: transparent;
    color: black;
    border-left: none;

    border-radius: 0px 15px 15px 0px !important;
    border: solid 1px lightgray;
}

.BtnEmail{
    background-color: transparent;
    color: black;
    border-left: none;

    border-radius: 0px 15px 15px 0px !important;
    border: solid 1px lightgray;
}
#email{
    border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/LoginCard/LoginForm/LoginForm.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,iBAAiB;AACrB;;;;AAIA;IACI,0BAA0B;IAC1B,mDAAmD;;;AAGvD;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,iBAAiB;;IAEjB,2CAA2C;IAC3C,2BAA2B;AAC/B;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,iBAAiB;;IAEjB,2CAA2C;IAC3C,2BAA2B;AAC/B;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".PasswordDiv{\n    width: 90%;\n    display: flex;\n    justify-content: center;\n    align-items: baseline;\n    /* padding: 5%; */\n}\n\n\n\n.PasswordDiv span {\n    transform:translateX(-50px)\n    /* transform: translateX(140px) translateY(15px); */\n\n\n}\n\n.BtnPsw{\n    background-color: transparent;\n    color: black;\n    border-left: none;\n\n    border-radius: 0px 15px 15px 0px !important;\n    border: solid 1px lightgray;\n}\n\n.BtnEmail{\n    background-color: transparent;\n    color: black;\n    border-left: none;\n\n    border-radius: 0px 15px 15px 0px !important;\n    border: solid 1px lightgray;\n}\n#email{\n    border-radius: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
