// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.riepilogBody{
    background-color: #FBFBFB;
    padding-left: 5%;
    
    border-radius: 0px 0px 12px 12px;
}

.riepilogHeader{

    padding-top: 5%;
    font-weight: bold;
    
    background-color: #FBFBFB;
}

.cardStyle{
    min-width: 300px
}`, "",{"version":3,"sources":["webpack://./src/components/RiepilogoCard/CardInfoRiepilogo/CardInfoRiepilogo.css"],"names":[],"mappings":";;AAEA;IACI,yBAAyB;IACzB,gBAAgB;;IAEhB,gCAAgC;AACpC;;AAEA;;IAEI,eAAe;IACf,iBAAiB;;IAEjB,yBAAyB;AAC7B;;AAEA;IACI;AACJ","sourcesContent":["\n\n.riepilogBody{\n    background-color: #FBFBFB;\n    padding-left: 5%;\n    \n    border-radius: 0px 0px 12px 12px;\n}\n\n.riepilogHeader{\n\n    padding-top: 5%;\n    font-weight: bold;\n    \n    background-color: #FBFBFB;\n}\n\n.cardStyle{\n    min-width: 300px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
