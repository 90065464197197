// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderListContainer .row {
    display: flex;
    justify-content: center;
    margin:0px;
    
}
.OrderListContainer .col {
    display: flex;
    justify-content: center;
    
}

.TitleList{
    display: flex;
    justify-content: space-between;

}

.OrderListContainer{
    background-color: white;
    border-radius: 12px;
    padding: 10px;
}
.OrderListContainer .row p{
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
}
.OrderCardHistory{
    width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/components/OrderList/OrderList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,UAAU;;AAEd;AACA;IACI,aAAa;IACb,uBAAuB;;AAE3B;;AAEA;IACI,aAAa;IACb,8BAA8B;;AAElC;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,UAAU;AACd","sourcesContent":[".OrderListContainer .row {\n    display: flex;\n    justify-content: center;\n    margin:0px;\n    \n}\n.OrderListContainer .col {\n    display: flex;\n    justify-content: center;\n    \n}\n\n.TitleList{\n    display: flex;\n    justify-content: space-between;\n\n}\n\n.OrderListContainer{\n    background-color: white;\n    border-radius: 12px;\n    padding: 10px;\n}\n.OrderListContainer .row p{\n    display: flex;\n    margin-bottom: 1rem;\n    justify-content: center;\n}\n.OrderCardHistory{\n    width: 70%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
