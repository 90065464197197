import { Button, Card, Form,  } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { useNavigate } from "react-router-dom";
import './LoginCard.css'
import { useEffect, useState } from "react";
import LoginForm from "./LoginForm/LoginForm";
import RegistrationForm from "./RegistrationForm/RegistrationForm";
import ResetForm from "./ResetForm/ResetForm";
import axios from 'axios'
import * as url from '../../backend.json'
import { useDispatch } from "react-redux";
import { updateAccount } from "../../store/reducers/Account/reducer";
const BASE_url= url
function LoginCard(){
    const navigate = useNavigate();
    const dispatch= useDispatch()
    const [mode, setMode]=useState(0);

    const switchingMode=(index)=>{
        if(index==0 || index==undefined){
            document.getElementsByClassName("loginstyle")[0].style["background-color"]="#A0CED9"
            document.getElementsByClassName("loginstyle")[1].style["background-color"]="#A0CED9"
            document.getElementsByClassName("containerCard")[0].style["background-color"]="white"
            // document.getElementsByClassName("rootDiv")[0].style["background-color"]="white"
            document.body.style.background="white"
        }
        else if(index==1){
            document.getElementsByClassName("loginstyle")[0].style["background-color"]="white"
            document.getElementsByClassName("loginstyle")[1].style["background-color"]="white"
            document.getElementsByClassName("containerCard")[0].style["background-color"]="#A0CED9"
            // document.getElementsByClassName("rootDiv")[0].style["background-color"]="#A0CED9"
            document.body.style.background="#A0CED9"
        }else{
            document.getElementsByClassName("loginstyle")[0].style["background-color"]="#A0CED9"
            document.getElementsByClassName("loginstyle")[1].style["background-color"]="#A0CED9"
            document.getElementsByClassName("containerCard")[0].style["background-color"]="white"
            // document.getElementsByClassName("rootDiv")[0].style["background-color"]="white"
            document.body.style.background="white"
            
        }
       
        setMode(index);
    }

    function getAccountDetails(){
        console.log("account details")
        axios.get(BASE_url.API+"accounts/profile/", {withCredentials:true}).then(e=>{
            console.log(e)
            dispatch(updateAccount(e.data))
            navigate("/")
        }).catch(err=>{
            console.log(err)
        })
    }

    const [token, setToken]=useState("");

    useEffect(()=>{
        
        const queryParameters = new URLSearchParams(window.location.search)
        const tk = queryParameters.get("token")
        console.log(tk)
        setToken(tk)
        if(tk!=null){
            
            setMode(2)
        }else{
            getAccountDetails()
        }
        

    }, [])

   
    return(
        <div className="containerCard ">
        
        <img width={"100px"} src="busketlogo.png"></img>
        <div id="titlelogin">BUSKET LINE</div>
        <div className="desktopform">
        
        { (mode==undefined || mode==0)?  <LoginForm ></LoginForm>: <></>}
        {mode== 2  ? <ResetForm token={token}></ResetForm> : <></>}
        {/* {mode==1 ?  <RegistrationForm ></RegistrationForm>: <></>} */}
        {/* {mode==2 ? <ResetForm></ResetForm>} */}
        
        {/* <LoginForm ></LoginForm> */}
        {/* <RegistrationForm></RegistrationForm> */}
        <div className="extraInfo">
            {mode==undefined || mode==0  || mode==2? 
            <p>Crea un account 
                <Button className="extraBTN" onClick={e=>{switchingMode(1)}}>Crea</Button>
            </p> :<></>
            }
            { mode==2? 
            <p>Accedi al tuo account account 
                <Button className={"extraBTN "} onClick={e=>{switchingMode(0)}}>Accedi</Button>
            </p> :<></>
            }
            {mode==undefined || mode==0 ? 
            <p>Reset Password 
                <Button className="extraBTN" onClick={e=>{switchingMode(2)}}>Reset</Button>
            </p> :<></>
            }
        </div>
        </div>

        <Card className="cardlogin loginstyle">
            <Card.Header className="cardHeaderLogin mobileForm loginstyle">login</Card.Header>
            <Card.Body className="cardBody mobileForm" style={mode==1? {display:"flex"}:{}}>
                
                { (mode==undefined || mode==0) ? <LoginForm ></LoginForm> : <></>}
                {(  mode==1) ? <RegistrationForm></RegistrationForm> : <></>}
                { mode== 2  ? <ResetForm token={token}></ResetForm> : <></>}
                <div className="extraInfo">
            {mode==undefined || mode==0  || mode==2? 
            <p>Crea un account 
                <Button className="extraBTN" onClick={e=>{switchingMode(1)}}>Crea</Button>
            </p> :<></>
            }
            {mode==1 || mode==2? 
            <p>Accedi al tuo account account 
                <Button className={"extraBTN "} onClick={e=>{switchingMode(0)}}>Accedi</Button>
            </p> :<></>
            }
            {mode==undefined || mode==0 ? 
            <p>Reset Password 
                <Button className="extraBTN" onClick={e=>{switchingMode(2)}}>Reset</Button>
            </p> :<></>
            }
        </div>
            </Card.Body>
        </Card>
        </div>
    )
}



export default LoginCard