import {createSlice} from '@reduxjs/toolkit'
import { act } from 'react'






// const consegna={
//     info:{},
//     categoria:{},
//     prodotto:{}
// }

// const prenotazione={
//     ritiro:{},
//     consegne:[]
// }

// const init={
//     carello:{
//        prenotazioni:[]
//     }

// } 


const init={
      carello: {
        prenotazioni: [
        
        ]
      }
    
}


export const PrenotazioneSlice = createSlice({
    name:"carello",
    initialState:init,
    reducers:{
        hydrate:(state, action) => {
            // do not do state = action.payload it will not update the store
            return action.payload
            },
        AddPrenotazione: (state, action)=> {
            // let payload= JSON.parse(action.payload)
            // console.log(payload, "redux Account")
            state.carello.prenotazioni.unshift(
                {
                    ritiro: action.payload,
                    consegne:[]
                }
               
                
                )
        },
        AddConsegna: (state, action)=> {
            // let payload= JSON.parse(action.payload)
            // console.log(payload, "redux Account")
            // const index = state.prenotazione.findIndex(object => {
            //     return object.id === action.payload.id;
            // });
            state.carello.prenotazioni[action.payload.index].consegne.push(action.payload.data);           
        },
        RemoveConsegna:(state, action)=>{
            // const index = state.carello.prenotazioni.findIndex(object => {
            //     return object.id === action.payload;
            // });
            state.carello.prenotazioni.splice(action.payload, 1);
        },
        AddPrenotazionePrice:(state, action)=>{
          state.carello.prenotazioni[action.payload.index]["price"]=action.payload
        },
        AddExtraPrice:(state,action)=>{

          state.carello.prenotazioni[action.payload.index]["metadatiPrice"]=action.payload
        }

        // EditProdotto :(state, action)=>{
        //     state.prenotazione.categoria.prodotti[action.payload.index]=action.payload.data
        // }
    },
   
})

export const { AddPrenotazione, AddConsegna, RemoveConsegna , hydrate} = PrenotazioneSlice.actions

export default PrenotazioneSlice.reducer
