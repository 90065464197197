
import {useState, useEffect} from 'react'

import { Container, Row, Col, ListGroupItem, Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import './CardFooter_gonext.css'
import { Button, Card } from 'react-bootstrap'
import CustomButton from '../../components__old/CustomButton/CustomButton'
import DivSpace from '../DivSpace/DivSpace';
import {  useNavigate } from 'react-router-dom';
import {  useDispatch, useSelector} from 'react-redux';
import { AddPrenotazione, RemoveConsegna } from '../../store/reducers/Prenotazione/reducer';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import {Spinner} from 'react-bootstrap';

import axios from 'axios';
import * as url from '../../backend.json'
import order from '../../store/reducers/Prenotazione/order';
const BASE_url= url
function CardFooterNext({step, state,  contenuto, account, setIndex}){
    const navigate= useNavigate()
    const carelloState= useSelector((state) => state["carello"])
    const ritiroinfo= useSelector(state=>state["ritiro"])
    const orders= useSelector(state=>state["order"])
    const dispatch = useDispatch()
    const [show, setShow]=useState(0)
    const [showloading, setShowLoadin]=useState(false)
    const [disabledBTN, setDisable]=useState(false)
    const [totalPrice, setTotalPrice]=useState([])
    const [mobileInfo, setMobileInfo]=useState(false)
    const [total, settotal]=useState(0)
    function GoNext(url){
        console.log(step)
        if(step==0 ){
            
            if(Object.keys(state).length>2 )
                dispatch(AddPrenotazione(ritiroinfo))
            
            navigate("/ordine/")
        }
        if(step==2 ){
            navigate("/ordine/riepilogo/carello/")
        }
        if(step==3 ){
            navigate("/ordine/riepilogo/carello/pagamento")
        }
        if(step==undefined){
            navigate(url)
            
            
        }
    
        // step==0 ? navigate("/prenotazione/") : navigate("/carello")
    }

    function GoHome(url){
        navigate("/")
    }

    function showInfo(index){
        console.log(index)

        setShow(index)
        if(setIndex!=undefined)
            setIndex(index)
    }

    useEffect(()=>{
        let tmp_show=[]
        let tmp_price=0
        let tmp_total=[]
        let product_price=0;
        carelloState.carello.prenotazioni.map((item, index)=>{
            tmp_price=0
            // tmp_price+=3.0
            product_price=0
            item.consegne.map((consegna, indexC)=>{
                console.log(consegna)
                console.log(parseFloat(consegna.prodotti.price)*consegna.prodotti.n*22/100)
                product_price+=(parseFloat(consegna.prodotti.price)*consegna.prodotti.n )
                if(consegna.info["zona pedonale"]){
                    tmp_price+=2;
                }
            })
            console.log("production price", index,product_price, product_price*22/100)
            tmp_price+=product_price+product_price*22/100
            
            tmp_total=tmp_total.concat(tmp_price.toFixed(2))
            tmp_show.push(false)
        })
        setShow(tmp_show)
        console.log("total",tmp_total)
        setTotalPrice(tmp_total)


    }, [carelloState])

    useEffect(()=>{
        console.log(carelloState)
        if(carelloState.carello.prenotazioni.length==0 && step!=3){
            navigate("/")
        }
    }, [carelloState])

    useEffect(()=>{
        console.log(carelloState)
        if(carelloState.carello.prenotazioni.length==0 && step!=3){
            setDisable(true)
            navigate("/")
        }
        
    },[])

    function showInfoMobile(){
        setMobileInfo(!mobileInfo)
    }

    function EliminaPrenotazione(idnex){
        dispatch(RemoveConsegna(idnex))
    }
    function Acquista(){
        axios.defaults.headers.common['X-CSRFToken'] = Cookies.get("csrftoken")
        console.log("Acquista", orders?.orders, orders)
        setShowLoadin(true)
        let resultsAPI=[]
        let errorApi=false
         orders?.orders.map((item,index)=>{
            console.log("body request")
      

        
            axios.post(BASE_url.API+"/orders/submit/" ,item, {withCredentials:true}).then(e=>{
                Swal.fire({
                    text :`L ordine ${index+1} è stato sottomesso corettamente`,
                    heightAuto: false,
                    icon: "success"
                  })
                  setShowLoadin(false)
            }).catch(e=>{
                console.log(e)
                Swal.fire({
                    text: `Errore nella sottomissione dell' ordine ${index+1}`,
                    heightAuto: false,
                    icon: "error"
                  })
                  setShowLoadin(false)
            })
           
            
            
        
        })

        

       
    }
    function add(accumulator, a) {
        return accumulator + a;
      }

    return (
    <>
    <Modal show={showloading}>

        <Modal.Body>
        <div className=" SpinnerPage modalSpinner">
               
               <Spinner  animation="border" variant="warning" />
               <h1>Loading... </h1>
               </div>
        </Modal.Body>
    </Modal>
   
    <Card id="CardFooterNext">
        <Card.Header className="ritiroHeader" onClick={e=>{showInfoMobile()}}>
            <div key={"asda"} id="upButton"className="headerDiv footerCard" >
              {!mobileInfo ? "^" : "_"}
            </div>
        
        </Card.Header>
        <Card.Body>
        {/* <Button className="ButtonNext ButtonNext_ordine" hidden={false} disabled={disabledBTN} onClick={(e=>{GoNext()})}>{"Aggiungi ordine"}</Button> */}
            {contenuto!= undefined ? contenuto: <></>}
            <div hidden={account ? true: false} className='footerCard'>
            <Button className="ButtonNext ButtonNext_ordine" hidden={step==0 ? true: false} disabled={disabledBTN} onClick={(e=>{GoHome("/")})}>Aggiungi ordine</Button>
            <Button className="ButtonNext" hidden={step==4} disabled={carelloState.carello.prenotazioni.length==0 && step!=0} onClick={(e=>{GoNext("/ordine/riepilogo")})}>{step==0 ? "Prosegui": step==2 ? "Carello": step==3 ? "Pagamento": "Prosegui"} </Button>
            <Button className="ButtonNext" hidden={step!=4} disable={orders?.orders==undefined } onClick={e=>{Acquista()}}>Acquista Ordine</Button>

            </div>
            <div className={!mobileInfo ? "InfoMobile_s desktop": "InfoMobile_h desktop"}>
            <h3>{carelloState.carello.prenotazioni.length>0 ?carelloState.carello.prenotazioni[show]?.ritiro?.data?.data+"-"+carelloState.carello.prenotazioni[show]?.ritiro?.data?.h: ""}</h3>
                        <p>{carelloState.carello.prenotazioni.length>0 ? carelloState.carello.prenotazioni[show]?.ritiro?.info["Indirizzo mittente"]: ""}</p>
            </div>
            { step!= 4 ?carelloState.carello.prenotazioni.map((item,index)=>(
                < >
                <Card key={index} hidden={step==2 || step==3} className={mobileInfo ? "InfoMobile_s": "InfoMobile_h"} style={show==index ?{background: "#FCF0CC"}: {background:"white"}} id={index} onClick={e=>{showInfo(index)}}>
                    <Card.Body>
                        <div className='TitleCardOrder'>
                        <h3>Ordine {index+1}</h3>
                        <Button className='EliminaBtn' onClick={e=>{EliminaPrenotazione(index)}}><img width={"20px"} src='/trash.png'></img></Button>
                        </div>
                        
                        <p>{item.ritiro.data.data}    -    {item.ritiro.data.h}</p>
                        <p>{item.ritiro.info["extrainfoAddress"]?.formatted_address}</p>
                        
                
                        <DivSpace></DivSpace>

                        {show!= index ? <>N: {item.consegne.length}</>: <>
                        <Card className="elenco">
                            <Card.Body>
                            <ul>
                                {item.consegne.length==0? "N:0 Consegne": ""}
                        {item.consegne.map((product)=>(
                            <>
                            <li><strong>{product.prodotti.name +" x"+product.prodotti.n }</strong></li>
                            <Card  className="addInfolist">
                                <p>- {product.info.h } - {typeof product.info.data == "string" ? product.info.data : ""} </p>
                                <p>- {product.info["extrainfoAddress"]?.formatted_address}</p>
                            </Card>
                            </>
                        ))}
                        </ul>
                        </Card.Body>
                        </Card>
                        </>}
                        

                    </Card.Body>
                </Card>
               
                <DivSpace distancepx={"20px"}></DivSpace>
                </>
            )):  <>{
                step==3 || step==4 ? <><Card  className={mobileInfo ? "InfoMobile_s": "InfoMobile_h"} style={{background:"white"}} >
                
                <Card.Header className='TotalPriceHeader'>
                    <h3>Total</h3>
                    <h3>€ {parseFloat(totalPrice.reduce(add, 0))}</h3>
                </Card.Header>
                <Card.Body>
                    
                    {totalPrice.map((item, index)=>(
                        <div className="OrderPrice">
                            <strong><p>#00{index+1}</p></strong>
                            <p>€ {item}</p>
                        </div>
                    ))}
                    
                </Card.Body>
                </Card>
                <DivSpace distancepx={"20px"}></DivSpace>
                 </>: <></>
            }
            
            </>}
        </Card.Body>
        </Card>
        </>)
}


export default CardFooterNext;