// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckGroup{
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    font-size: 12px;
}
.ChecboxValue{
    display: flex;
    flex-direction: column;
    width: 60px;
    gap: 10px;
    font-weight: bold;
    align-items: center;
    justify-content: flex-start;
}


@media only screen and (max-width: 767px) {

    .ChecboxValue{
        width: 30px;
        gap: 5px;
        font-size: 10px;
        display: flex;
        word-wrap: break-word;
    white-space: pre-wrap;
    }
    .ChecboxValue img{
        width: 30px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1050px){
    .ChecboxValue{
        width: 25px;
        gap: 12px;
        font-size: 10px;
        display: flex;
        word-wrap: break-word;
    white-space: pre-wrap;
    }
    .ChecboxValue img{
        width: 30px;
    }
  }
  @media only screen and (min-width: 1050px) {

  }`, "",{"version":3,"sources":["webpack://./src/components/CardExtraPrice/CardExtraPrice.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,SAAS;IACT,iBAAiB;IACjB,mBAAmB;IACnB,2BAA2B;AAC/B;;;AAGA;;IAEI;QACI,WAAW;QACX,QAAQ;QACR,eAAe;QACf,aAAa;QACb,qBAAqB;IACzB,qBAAqB;IACrB;IACA;QACI,WAAW;IACf;EACF;EACA;IACE;QACI,WAAW;QACX,SAAS;QACT,eAAe;QACf,aAAa;QACb,qBAAqB;IACzB,qBAAqB;IACrB;IACA;QACI,WAAW;IACf;EACF;EACA;;EAEA","sourcesContent":[".CheckGroup{\n    background-color: white;\n    border-radius: 15px;\n    padding: 10px;\n    display: flex;\n    justify-content: center;\n    font-size: 12px;\n}\n.ChecboxValue{\n    display: flex;\n    flex-direction: column;\n    width: 60px;\n    gap: 10px;\n    font-weight: bold;\n    align-items: center;\n    justify-content: flex-start;\n}\n\n\n@media only screen and (max-width: 767px) {\n\n    .ChecboxValue{\n        width: 30px;\n        gap: 5px;\n        font-size: 10px;\n        display: flex;\n        word-wrap: break-word;\n    white-space: pre-wrap;\n    }\n    .ChecboxValue img{\n        width: 30px;\n    }\n  }\n  @media only screen and (min-width: 768px) and (max-width: 1050px){\n    .ChecboxValue{\n        width: 25px;\n        gap: 12px;\n        font-size: 10px;\n        display: flex;\n        word-wrap: break-word;\n    white-space: pre-wrap;\n    }\n    .ChecboxValue img{\n        width: 30px;\n    }\n  }\n  @media only screen and (min-width: 1050px) {\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
