import { Button } from "react-bootstrap";
import './AddCard.css'
import { useDispatch, useSelector } from "react-redux";
import { AddConsegna } from "../../store/reducers/Prenotazione/reducer";
import { useNavigate } from "react-router-dom";


function AddCard({state, indexPrenotazione, restore}){
    const dispatch= useDispatch()
    const navigate = useNavigate()
    const prenotazione= useSelector(state=>state["prenotazione"])
    function Add(){
        console.log(state)
        console.log(prenotazione)
        dispatch(AddConsegna({ index:indexPrenotazione, data:prenotazione}))
        restore()
        window.scrollTo(0, 0);

    }

    return (
        <div>
        <div className="divAddCard">
            <Button className="AddCardStyle" onClick={e=>{Add(e)}}>
                <div id="add">+</div>
                <div id="infoAdd">Aggiungi Consegna</div>
            </Button>
            
        </div>
        <div id="ExtraInfo">Per ongi prodotto aggiuntivo per la stessa destinazione si risparmia il <strong>50%</strong></div>
        </div>
    )
}


export default AddCard