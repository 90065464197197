import { Card } from "react-bootstrap";

import './CardInfoRiepilogo.css'




function CardInfoRiepilogo({info}){
    

    return (
        <Card className="cardStyle">
            <Card.Header className="riepilogHeader">
                {info?.title}
            </Card.Header>
            <Card.Body className="riepilogBody">
                {info?.data?.map(items=>(
                    items
                ))}
            </Card.Body>
        </Card>
    )
}

export default CardInfoRiepilogo