import { useEffect, useState } from "react"




function DivSpace({distancepx}){

    const [distance, setDistance]= useState("10px")
    useEffect(()=>{
        if(distancepx)
        setDistance(distancepx)
        console.log(distancepx, distance)
    }, [distancepx])
    return (
        <div style={{marginTop:distance}}></div>
    )
}

export default DivSpace