
import axios from 'axios'
import {useState, useEffect} from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import { updateAccount } from '../../store/reducers/Account/reducer'
import * as url from '../../backend.json'
import './AccountCard.css'
import { Button, Card, Container, Form, Row, Col  } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import DivSpace from '../../components/DivSpace/DivSpace'
import Menu from '../../components/menu/menu'
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
const BaseURL= url;
function AccountCard({globalstate, SwitchMode, mode, showData,ChangePSW,SendUpdate, updateInfo, updatePSW}){

    return(

        <div className='App AccountPage DesktopPage'>
      
        <DivSpace distancepx={"50px"}></DivSpace>
        
       
               
                <Card className='AccountCard'>
                <Card.Body className="AccountCardBody">
                    <div className={(globalstate.account.payload!=undefined && !globalstate.activate) ? "containerJustPSW": "containerDiv"}>
                       <Row>
                       {globalstate.activate ? <Col>
                            <div className='SwitchBtn'>
                                <Button variant="secondary"onClick={e=>{
                                    SwitchMode(0)
                                }}>General</Button>
                                <Button  className="BtnBilling" onClick={e=>{
                                    SwitchMode(1)
                                }}>Billing</Button>
                                <Button className="BtnOrder" onClick={e=>{
                                    SwitchMode(1)
                                }}>Orders</Button>
                            </div>
                            </Col>:<></>}
                            <Col>
                            <Container className="ContainerAccount DesktopContainer">
                        <Row>
                        <h3>{mode==0 ? "Account Info" : "Billing Info"}</h3>
                        <DivSpace distancepx={"20px"}></DivSpace>
                        <h3 style={{color:"grey"}}>{globalstate.account.payload!=undefined ? globalstate.account.payload.username: ""}</h3>

                    {(globalstate.account.payload!=undefined && globalstate.activate) ?Object.keys(globalstate?.account?.payload).map(item=>(
                        item!="metadata" && showData(item, mode)?    
                                        <Col key={item}>
                                        <FloatingLabel
                                            key={item}
                                            controlId={item}
                                            label={item}
                                            className="mb-3 AccountInfo">
                                        <Form.Control  key={item}type="text" placeholder={globalstate.account.payload[item]} disabled={["username", "email", "id"].includes(item)? true : false} defaultValue={globalstate.account.payload[item]} onChange={e=>{updateInfo(item, e.target.value)}} animation="glow" />
                                        </FloatingLabel>
                                        </Col>
                                    
                         : <></>
                    )): <></>}
                     <div className='Divbtnupdate'>

                     {globalstate.account.payload!=undefined && globalstate.activate ?<Button className="ChangeBTN UpdateBTN" onClick={e=>{SendUpdate()}}>Update</Button> : <></>}
                     </div>
                    
                    </Row>
                    <DivSpace distancepx={"50px"}></DivSpace>
                    {mode==0  ?<Row>
                                    <div className="container ContainerAccount ChangePSW">
                                        <h3>Change Password</h3>
                   
                   <FloatingLabel
                                       
                                       controlId={"password1"}
                                       label={"old password"}
                                       className="mb-3 AccountInfo">
                                   <Form.Control type="password" placeholder={"password"} onChange={e=>{updatePSW("old_password", e.target.value)}}/>
                                   </FloatingLabel>
       
   
                   <FloatingLabel
                                       
                                       controlId={"password1"}
                                       label={"new password"}
                                       className="mb-3 AccountInfo">
                                   <Form.Control type="password" placeholder={"password"} onChange={e=>{updatePSW("new_password", e.target.value)}}/>
                                   </FloatingLabel>
                                   <Button className='ChangeBTN UpdateBTN' onClick={e=>{ChangePSW()}}>Submit</Button>
                                   </div>
                    </Row>: <></>}
                    </Container>
                            </Col>
                        </Row>
                    </div>

                    <DivSpace distancepx={"20px"}></DivSpace>
                    
                </Card.Body>
            </Card>
               
        
        </div>
    )
}

export default AccountCard