// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.cardHeaderLogin{
    background-color: #A0CED9 !important ;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":";;AAEA;IACI,qCAAqC;AACzC","sourcesContent":["\n\n.cardHeaderLogin{\n    background-color: #A0CED9 !important ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
