import React from 'react';
import './Index.css'; // Make sure to create a corresponding CSS file for styling

const Ordini = () => {
  return (
    <div className="app">
      <header className="header">
        <button className="close-button">X</button>
        <h1>Prenota</h1>
      </header>
      <nav className="navbar">
        {/* Navigation items here */}
      </nav>
      <main className="content">
        <h2>Cosa ?</h2>
        <div className="items-grid">
          {/* Generate these buttons dynamically if you have a list of items */}
          <button className="grid-item">Bags</button>
          <button className="grid-item">Flowers</button>
          {/* ... other items */}
        </div>
        <footer className="footer">
          <button className="back-button">Indietro</button>
          <button className="next-button">Avanti</button>
        </footer>
      </main>
    </div>
  );
};

export default Ordini;