import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {NavDropdown} from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import * as url from '../../backend.json'
import axios from 'axios'
import './menu.css'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
const baseurl=url;


function Menu(){
  const navigate= useNavigate()
  const [activebtn, setActivate]=useState([false,false,false,false,false])
  const Logout = () =>{
        axios.post(baseurl.API+'accounts/logout/',{}, {withCredentials: true}).then(e=>{
          console.log(e);
          window.location.href = '/login'; 
        }).catch(e=>{
          console.log(e)
          window.location.href = '/login'; 
        })
    }

  function ChangePage(url, i){
    let tmp=[false,false,false,false,false]
    tmp[i]=true
    setActivate(tmp)
    navigate(url)
  }
    return (
<Navbar key={false} collapseOnSelect expand="lg" className="bg-body-tertiary">
<Container fluid>
  
  <Navbar.Brand href="#" >

    <div className="logoNavBar">
    <img width={"35px" } src="/busketlogo.png"></img>
    <div>BUSKET LINE</div>
    </div>

    </Navbar.Brand>

  
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
  <Navbar.Offcanvas
    id={`offcanvasNavbar-expand-${false}`}
    aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
    placement="end"
  >
    <Offcanvas.Header closeButton>
      <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`}>
        Menu
      </Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body >
      <Nav  className="me-auto">
        <Button active={activebtn[0]} className="btnMenu" onClick={e=>{ChangePage("/", 0)}} >+ Spedizione</Button>
        <Button active={activebtn[1]} className="btnMenu"onClick={e=>{ChangePage("/cronologia/progress", 1)}}>In Corso</Button>
        <Button active={activebtn[2]} className="btnMenu"onClick={e=>{ChangePage("/cronologia/submitted",2 )}}>Programmata</Button>
        <Button active={activebtn[3]} className="btnMenu"onClick={e=>{ChangePage("/cronologia/final",3)}}>Precedenti</Button>
        
        {/* <Button active={activebtn[4]} className="btnMenu"onClick={Logout}>Logout</Button> */}
      </Nav>

      <Nav>
        <div className="extrainfomenu">
      <Button className="helpbtn" onClick={e=>{ChangePage("/prenotazione/pagamento")}}>serve aiuto?</Button>

      
      <Dropdown className="ddProfile">
      <Dropdown.Toggle id="dropdown-basic">
      <img width={"20px"} src="/profile.png"></img>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item className="btnMenu" onClick={e=>{ChangePage("/account")}}>Profilo</Dropdown.Item>
        <Dropdown.Item className="btnMenu" onClick={Logout}>Logout</Dropdown.Item>
       
      </Dropdown.Menu>
    </Dropdown>
    <Button className="btnMenu logobtn" onClick={e=>{ChangePage("/ordine/riepilogo/carello")}}><img src="/carellomenu.png"></img></Button>
      </div>
      </Nav>

    </Offcanvas.Body>
  </Navbar.Offcanvas>
    </Navbar.Collapse>
</Container>
</Navbar>
    )
}





export default Menu