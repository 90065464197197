import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useEffect, useState } from 'react';
import Ordini from './pages/Ordini/Index'
import NavigationBottomBar from './components__old/navbar/navbar'
import CustomCard from './components__old/customcard/customcard'
import CircleBackground from './components__old/CircleBackground/CircleBackground'
import FooterButton from './components__old/footerButton/footerButton'
import PrenotaHeader from './components__old/PrenotaHeader/PrenotaHeader';
import ColloCard from './components/DynamicCard/ColloCard';
import AddCard from './components/AddCard/AddCard';
import RitiroCard from './components/RitiroCard/RitiroCard';
import DivSpace from './components/DivSpace/DivSpace';
import CardConsegna from './components/CardConsegna/CardConsegna';
import { CardFooter } from 'react-bootstrap';
import CardFooterNext from './components/CardFooter_gonext/CardFooter_gonext';
import Menu from './components/menu/menu';
import CardMittenteDestinatario from './components/CardMittente_Destinatatio/CardMittenteDest'
import CardTransportoDetails from './components/CardTransportoDetails/CardTransportoDetails';
import CardExtraPrice from './components/CardExtraPrice/CardExtraPrice';
import StepsPage from './components/StepsPage/StepsPage';
function App() {
  const [state, setState]=useState({
    data:{},
    info:{}
  })
  useEffect(()=>{
    setState({
      data:{},
      info:{}
    })
  }, [])
  const infoCardsMittente={
    title:"dati di mittente",
    input:[
        {
            label:"Nome",
            type:"text"
        },
        {
          label:"Cognome",
          type:"text"
      },
        {
            label:"indirizzo mittente",
            type:"address",
            ref:"googleaddress"
        },
        {
            label:"Insegne Negozio o Nome-Numero cit Mittente",
            type:"text"
        },
        {
            label:"Email",
            type:"text"
        },
        {
            label:"telefono",
            type:"text"
        },
        {
            label:"Nota",
            type:"textarea"
        }
    ]
}
const  UpdateState=(key, value)=>{
  let splitted= key.split(".")
  console.log(splitted)
  if(splitted.length>1){
 
      let tmp=state;
      tmp[splitted[0]][splitted[1]]=value
      setState(tmp)
      console.log("tmp",tmp)
    
    
  }else{
    setState({...state, [key]:value})
  }
  


}

  return (
    <>
    
    <div className="App">
    <Menu></Menu>

    <CardFooterNext step={0} state={state}></CardFooterNext>
      {/* <div id="containerMain"> 
        <div id="divMain">

        </div>
      </div>
      <div id="divHeader"> */}
          {/* <PrenotaHeader></PrenotaHeader> */}
        {/* </div> */}
      {/* <CustomCard> */}
      <div className="CardDiv">
      <DivSpace distancepx="50px"></DivSpace>
      
      <StepsPage position={0}></StepsPage>
        <DivSpace distancepx={"20px"}></DivSpace>
      <RitiroCard UpdateState={state}></RitiroCard>
      <DivSpace distancepx="10px"></DivSpace>
      {/* <DivSpace distancepx={"50px"}></DivSpace> */}
      <CardMittenteDestinatario type={0} indexPrenotazione={undefined} update={UpdateState} UpdateState={state} info={infoCardsMittente}></CardMittenteDestinatario>
      {/* <CardConsegna></CardConsegna> */}
      <DivSpace distancepx="10px"></DivSpace>
      {/* <ColloCard></ColloCard> */}
      <CardExtraPrice></CardExtraPrice>
      <DivSpace distancepx="10px"></DivSpace>
      {/* <AddCard></AddCard> */}
      <DivSpace distancepx="50px"></DivSpace>
      {/* </CustomCard> */}
      {/* <FooterButton></FooterButton> */}
      {/* <NavigationBottomBar></NavigationBottomBar> */}
      <DivSpace distancepx="150px"></DivSpace>
      </div>
    </div>
    
    </>
  );
}

export default App;
