import { useSelector } from 'react-redux'
import AddCard from '../../components/AddCard/AddCard'
import CardConsegna from '../../components/CardConsegna/CardConsegna'
import CardFooterNext from '../../components/CardFooter_gonext/CardFooter_gonext'
import CardMittenteDestinatario from '../../components/CardMittente_Destinatatio/CardMittenteDest'
import DivSpace from '../../components/DivSpace/DivSpace'
import ColloCard from '../../components/DynamicCard/ColloCard'
import Menu from '../../components/menu/menu'
import './ConsegnaPage.css'
import {useEffect, useState} from 'react'
import CardExtraPrice from '../../components/CardExtraPrice/CardExtraPrice'
import CardTransportoDetails from '../../components/CardTransportoDetails/CardTransportoDetails'
import StepsPage from '../../components/StepsPage/StepsPage'

const tmpButtons=[
  {
    img: "bottiglie.png",
    descrizione: "Vino rosso", 
    id:0,
    w:60,
    h:60,
    mh:30,
    mw:30,
    activate:false
    
  },
  {
    img: "fiori.png",
    descrizione: "mazzo di fiori", 
    id:1,
    w:60,
    h:60,
    mh:30,
    mw:30,activate:false
    
  },
  {
    img: "torta.png",
    descrizione: "mazzo di fiori", 
    id:2,
    w:60,
    h:60,
    mh:30,
    mw:30,activate:false
  },
  {
    img: "box.png",
    descrizione: "box", 
    id:3,
    w:60,
    h:60,
    mh:30,
    mw:30,activate:false
  },
  {
    img: "dolcino.png",
    descrizione: "pasticceria", 
    id:4,
    w:60,
    h:60,
    mh:30,
    mw:30,activate:false
  },
  {
    img: "frutta.png",
    descrizione: "vassoio di frutta", 
    id:5,
    w:60,
    h:60,
    mh:30,
    mw:30,activate:false
    
  },
  {
    img: "scatole.png",
    descrizione: "scatole", 
    id:6,
    w:60,
    h:60,
    mh:30,
    mw:30,activate:false
  },
  {
    img: "fiori.png",
    descrizione: "mazzo di fiori", 
    id:7,
    w:60,
    h:60,
    mh:30,
    mw:30,activate:false
    
  },
  {
    img: "vaso_di_fiori.png",
    descrizione: "vaso di fiori", 
    id:8,
    w:60,
    h:60,      
    mh:30,
    mw:30,activate:false
  },
  {
    img: "box.png",
    descrizione: "box", 
    id:9,
    w:60,
    h:60,
    mh:30,
    mw:30,activate:false
  },
  {
    img: "torta.png",
    descrizione: "mazzo di fiori", 
    id:10,
    w:60,
    h:60,
    mh:30,
    mw:30,activate:false
  },
  {
    img: "Vector.png",
    descrizione: "Altro", 
    id:11,
    w:60,
    h:60,
    mh:10,
    mw:30,activate:false
  }

]

const tmpButtonsCard=[
  {
    img: "bottiglie.png",
    descrizione: "Sacchetti e Shopper", 
    id:0,
    w:60,
    h:80,
    mh:30,
    mw:30,
    activate:false
    
  },
  {
    img: "fiori.png",
    descrizione: "Vini e bevande", 
    id:1,
    w:60,
    h:80,
    mh:30,
    mw:30,activate:false
    
  },
  {
    img: "torta.png",
    descrizione: "Scatole e pacchi", 
    id:2,
    w:60,
    h:80,
    mh:30,
    mw:30,activate:false
  },
  {
    img: "box.png",
    descrizione: "Libri e riviste", 
    id:3,
    w:60,
    h:80,
    mh:30,
    mw:30,activate:false
  },
  {
    img: "dolcino.png",
    descrizione: "Stampe Afffiches quadri", 
    id:4,
    w:60,
    h:80,
    mh:30,
    mw:30,activate:false
  },
  {
    img: "frutta.png",
    descrizione: "Buste e documenti2", 
    id:5,
    w:60,
    h:80,
    mh:30,
    mw:30,activate:false
    
  },
  {
    img: "scatole.png",
    descrizione: "Fiori e piante", 
    id:6,
    w:60,
    h:80,
    mh:30,
    mw:30,activate:false
  },
  {
    img: "fiori.png",
    descrizione: "Pasticceria e Gelati", 
    id:7,
    w:60,
    h:80,
    mh:30,
    mw:30,activate:false
    
  },
  {
    img: "Vector.png",
    descrizione: "Altro", 
    id:11,
    w:60,
    h:80,
    mh:10,
    mw:30,activate:false
  }
]
function ConsegnaPage({info}){
  let init={
    data:{},
    info:{}

  }

  const form =useSelector(state=>state["form"])
  const [stateCategoria, setCategoria]=useState({
    
  })
  const [prodot,setProd]=useState({})
  const [stateInfo, setState]= useState(init)

  const [index_p, setIndex]=useState(0)

  const [Buttons, setButtons]=useState([])
  
  const [ButtonsCard, setButtonsCard]=useState([])

  const [selectCategories, setSelect]=useState(0)
    const infoCardsMittente={
        title:"dati di mittente",
        input:[
            {
                label:"Nome e Cognome",
                type:"text"
            },
            {
                label:"Indirizzo Mittente",
                type:"text"
            },
            {
                label:"Insegne Negozio o Nome-Numero cit Mittente",
                type:"text"
            },
            {
                label:"Email",
                type:"text"
            },
            {
                label:"telefono",
                type:"text"
            },
            {
                label:"Nota",
                type:"text"
            }
        ]
    }
    const infoCardsDest={
        title:"dati di Destinatario",
        input:[
            {
                label:"Nome",
                type:"text"
            },
            {
              label:"Cognome",
              type:"text"
          },           
          {
                label:"Indirizzo Destinatario",
                type:"address",
                ref:"googleref"
            },
            {
                label:"Insegne Negozio o Nome-Numero cit destinatario",
                type:"text"
            },
            {
                label:"Email",
                type:"text"
            },
            {
                label:"Telefono",
                type:"text"
            },
            {
                label:"Nota",
                type:"textarea"
            }
        ]
    }
    // {
    //   img: "frutta.png",
    //   descrizione: "Buste e documenti", 
    //   id:5,
    //   w:60,
    //   h:80,
    //   mh:30,
    //   mw:30,activate:false
      
    // },
    useEffect(()=>{
      console.log(form.select)
      let tmplist=[]

      console.log("inizio")
      form.message.categories.map((item, index)=>{
        let tmpjson={
          img: "frutta.png",
          descrizione: "", 
          id:5,
          w:60,
          h:80,
          mh:30,
          mw:30,activate:false,
          extrainfo:item
          
        }
        tmpjson.img=item["icon"]
        tmpjson.descrizione=item["label"]

        tmplist.push(tmpjson)
      })
      console.log("list", tmplist)
      setButtonsCard(tmplist)
      console.log(ButtonsCard)

    }, [])
    useEffect(()=>{
      let index_select=form.select
      let tmp_list_p=[]
      
   
      form.message.categories[index_select]["products"].map((item, index)=>{
        let tmp_p_json= {
          img: "bottiglie.png",
          descrizione: "Sacchetti e Shopper", 
          id:0,
          w:60,
          h:80,
          mh:30,
          mw:30,
          activate:false,
          extrainfo:item
          
        }
        tmp_p_json["img"]=item["icon"]
        tmp_p_json["descrizione"]=item["label"]
        tmp_list_p.push(tmp_p_json)
      })

      setButtons(tmp_list_p)
    }, [form.select])


    const  UpdateState=(key, value)=>{
      let splitted= key.split(".")
      console.log(key, value)
      let tmp=stateInfo
      if(splitted.length>1){
        setState({...stateInfo, [[splitted[1]]]:value})
          // console.log(splitted[0],splitted[1], tmp[splitted[0]][splitted[1]])
          // let tmp_i=tmp[splitted[0]]
          // console.log(tmp_i)
          // tmp_i[splitted[1]]=value

          // setState(tmp)
          console.log("tmp",stateInfo)
        
        
      }else{
        console.log("else")
        setState({...stateInfo, [key]:value})
      }
      console.log(stateInfo)

    
    }
    const updateCat=(key, value)=>{
      setCategoria({...stateCategoria, [key]:value})
    }
    const updateProd=(key,value)=>{
      console.log(prodot)
      console.log(key, value)
      setProd({...prodot, [key]:value})
      console.log(prodot)
    }

    const restore=()=>{
      // setState(init)
    }
    return (
    <div className='App MittentePage'>

        <Menu></Menu>
        <div className="CardDiv">

            <DivSpace distancepx={"50px"}></DivSpace>
            <StepsPage position={1}></StepsPage>
            <DivSpace distancepx={"20px"}></DivSpace>
            <CardConsegna update={UpdateState} ></CardConsegna>
            <DivSpace distancepx={"20px"}></DivSpace>
            <CardMittenteDestinatario type={1} indexPrenotazione={index_p} update={UpdateState} info={infoCardsDest} ></CardMittenteDestinatario>
            <DivSpace distancepx={"20px"}></DivSpace>
            <ColloCard cat={updateCat} prod={updateProd} key={1} update={UpdateState} statepage={stateInfo}  Buttons={ButtonsCard} type={"Collo"}></ColloCard>
            <DivSpace distancepx={"20px"}></DivSpace>
            <ColloCard  cat={updateCat} prod={updateProd} key={2} update={UpdateState} statepage={stateInfo}   Buttons={Buttons}></ColloCard>
            <DivSpace distancepx={"20px"}></DivSpace>
            <CardExtraPrice></CardExtraPrice>
            <DivSpace distancepx={"20px"}></DivSpace>
            <CardTransportoDetails></CardTransportoDetails>
            <DivSpace distancepx={"20px"}></DivSpace>
            <AddCard update={UpdateState} indexPrenotazione={index_p} state={{
              info:stateInfo, categoria:stateCategoria, prodotti:prodot
            }} restore={restore}></AddCard>
            <DivSpace distancepx={"150px"}></DivSpace>
            <CardFooterNext setIndex={setIndex}></CardFooterNext>
        </div>
    </div>)
}


export default ConsegnaPage