// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.headerCard{
    font-weight: bold;
    background-color: #D2E0E3 ;
    border: none;
}

.BodyCard{
    border-radius: 0px 0px 12px 12px;
    padding: 5%;
    background-color: #D2E0E3 ;
    /* display: flex; */
}

.customRow{
    --bs-gutter-x:0 !important;
    gap: 10px;
}


`, "",{"version":3,"sources":["webpack://./src/components/RiepilogoCard/RiepilogoCard.css"],"names":[],"mappings":";;;AAGA;IACI,iBAAiB;IACjB,0BAA0B;IAC1B,YAAY;AAChB;;AAEA;IACI,gCAAgC;IAChC,WAAW;IACX,0BAA0B;IAC1B,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,SAAS;AACb","sourcesContent":["\n\n\n.headerCard{\n    font-weight: bold;\n    background-color: #D2E0E3 ;\n    border: none;\n}\n\n.BodyCard{\n    border-radius: 0px 0px 12px 12px;\n    padding: 5%;\n    background-color: #D2E0E3 ;\n    /* display: flex; */\n}\n\n.customRow{\n    --bs-gutter-x:0 !important;\n    gap: 10px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
