// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #FFF7E0; /* Sample background color from the image */
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background-color: #000; /* Adjust based on the image */
    color: #fff;
  }
  
  .navbar {
    /* Styling for your navbar */
  }
  
  .content {
    flex: 1 1;
    padding: 1em;
  }
  
  .items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1em;
  }
  
  .grid-item {
    /* Style your grid items */
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 1em;
  }
  
  /* Add responsive styling for iPhone Pro Max */
  @media only screen and (max-width: 428px) { /* iPhone Pro Max screen width */
    /* Responsive styles */
  }
  
  
  
  
  
  `, "",{"version":3,"sources":["webpack://./src/pages/Ordini/Index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,yBAAyB,EAAE,2CAA2C;EACxE;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,sBAAsB,EAAE,8BAA8B;IACtD,WAAW;EACb;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,SAAO;IACP,YAAY;EACd;;EAEA;IACE,aAAa;IACb,4DAA4D;IAC5D,QAAQ;EACV;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,YAAY;EACd;;EAEA,8CAA8C;EAC9C,4CAA4C,gCAAgC;IAC1E,sBAAsB;EACxB","sourcesContent":[".app {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    background-color: #FFF7E0; /* Sample background color from the image */\n  }\n  \n  .header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 1em;\n    background-color: #000; /* Adjust based on the image */\n    color: #fff;\n  }\n  \n  .navbar {\n    /* Styling for your navbar */\n  }\n  \n  .content {\n    flex: 1;\n    padding: 1em;\n  }\n  \n  .items-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));\n    gap: 1em;\n  }\n  \n  .grid-item {\n    /* Style your grid items */\n  }\n  \n  .footer {\n    display: flex;\n    justify-content: space-between;\n    padding: 1em;\n  }\n  \n  /* Add responsive styling for iPhone Pro Max */\n  @media only screen and (max-width: 428px) { /* iPhone Pro Max screen width */\n    /* Responsive styles */\n  }\n  \n  \n  \n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
