import LoginCard from "../../components/LoginCard/LoginCard"
import "./Login.css"
function LoginPage(){

    return(
        <>
        <LoginCard>
            </LoginCard></>
    )
}


export default LoginPage