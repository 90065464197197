import { useEffect, useState} from "react"
import { Card, Col, Row } from "react-bootstrap"

import './RiepilogoCard.css'


function CarelloCard({infoCard}){
    const [rows, setrows]= useState([])
    const [state, setState]= useState([])
    useEffect(()=>{
        console.log(infoCard)
        let rows_tmp = [];
        let state_tmp = [];

        rows_tmp=infoCard.cards.slice(0, 2);
        console.log(rows_tmp)
        setrows(rows_tmp)
        state_tmp=infoCard.cards.slice(2,infoCard.cards.length)
        setState(state_tmp)
        console.log("use Effect")
        
    }, [infoCard])
    return(
        <Card className="cardRiepilogo">
            <Card.Header className="headerCard">
                <h3>{infoCard?.title}</h3>
            </Card.Header>
            <Card.Body className="BodyCard">
      
                {
                    <Row className="customRow">
                        {rows.map(item=>(
                            <Col className="customCol">
                                {item}
                            </Col>
                        ))}
                    </Row>
                }
                {state.map(item2=>(
                    item2
                ))}
            </Card.Body>
        </Card>
    )
}


export default CarelloCard