import AccountCard from "../../components/AccountCard/AccountCard";
import Menu from "../../components/menu/menu";
import AccountCardMobile from "../../components/AccountCard/AccountCardMobile";


import axios from 'axios'
import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateAccount, activateAccount } from '../../store/reducers/Account/reducer'
import * as url from '../../backend.json'

import './Account.css'

import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { Spinner , Card} from "react-bootstrap";
import DivSpace from "../../components/DivSpace/DivSpace";
const BaseURL= url;



function AccountPage(){
    // axios.defaults.xsrfCookieName = 'csrftoken';
    // axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    // axios.defaults.withCredentials = true;
    axios.defaults.headers.common['X-CSRFToken'] = Cookies.get("csrftoken")
    const dispatch= useDispatch()
    const [show, setShow]=useState(false)
    const globalstate=useSelector((state) => state["user"])
    const generalInfo=["first_name", "last_name", "phone", "email"]
    const removeitem=["id", "account_state", "previous_login", "username",]
    let UpdateJson={}
    const [NewPsw, setNEWPSW]=useState({
        old_password:"",
        new_password:""
    })
    const [mode, setMode]=useState(0)
    function SwitchMode(index){
        setMode(index)
    }

    function updatePSW(key, value){
        NewPsw[key]=value
    }
    function ChangePSW(){
        axios.post(BaseURL.API+"accounts/change_password/",NewPsw,{withCredentials:true}).then(e=>{
            console.log(e)
            Swal.fire({
                text: "La psw è sta cambiata correttamente",
                heightAuto: false,
                icon: "success"
              })
        }).catch(err=>{
            console.log(err)
            Swal.fire({
                text: err.response.data.error,
                heightAuto: false,
                icon: "error"
              })
        })
    }
    function showData(item,mode){
        if(mode==0)
        return generalInfo.includes(item)
        else{
        return (!generalInfo.includes(item) && !removeitem.includes(item))
        }
    }
    useEffect(()=>{
        console.log(globalstate)
        // console.log(globalstate)
        if(!globalstate.update){

            axios.get(BaseURL.API+"accounts/profile/", {withCredentials:true}).then(e=>{
                dispatch(updateAccount(e.data))
                dispatch(activateAccount())
                setShow(true)
            }).catch(err=>{
                // window.location.href="/2login"
                console.log(err)

            })
        }else{
            if(globalstate.account.payload["account_state"]=="active"){
                dispatch(activateAccount())
                setShow(true)
               
            }
        }
        // console.log(globalstate)
    },[])

    function updateInfo(key, value){
        console.log(key, value)
        UpdateJson[key]=value;
    }

    function SendUpdate(){
        axios.post(BaseURL.API+"accounts/profile/", UpdateJson, {withCredentials:true, withXSRFToken:Cookies.get("csrftoken")}).then(e=>{
            console.log(e)
            Swal.fire({
                text: "info cambiate correttamente",
                heightAuto: false,
                icon: "success"
              })
            UpdateJson={}
        }).catch(err=>{
            Swal.fire({
                text: "Errore durante il cambio info ",
                heightAuto: false,
                icon: "error"
              })
            console.error(err)
        })
    }

    return(
        <>
        <Menu></Menu>
        {
            globalstate.account.payload!=undefined && show ?
            <>
                <AccountCard globalstate={globalstate} SwitchMode={SwitchMode} mode={mode} showData={showData} ChangePSW={ChangePSW} SendUpdate={SendUpdate} updateInfo={updateInfo} updatePSW={updatePSW}></AccountCard>
                <AccountCardMobile globalstate={globalstate} SwitchMode={SwitchMode} mode={mode} showData={showData} ChangePSW={ChangePSW} SendUpdate={SendUpdate} updateInfo={updateInfo} updatePSW={updatePSW}></AccountCardMobile>
            </>: <div className=" SpinnerPage">
               
            <Spinner  animation="border" variant="warning" />
            <h1>Loading... </h1>
            </div>
        }
        
        </>
    )
}

export default  AccountPage