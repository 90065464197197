// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payBtn{
  height: 60px;
    width: 100px;
    background-color: transparent;
    border: none;
    display: flex;
    text-align: center;
    justify-content: space-between;
    flex-direction: column;
    color: grey;
    align-content: center;
    align-items: center;

}
.CardPagamento{
  white-space: pre-wrap;
    word-wrap: break-word;
}
.buttonMethod{
  background-color: white  ;
  border: none;
}
.colPay{
  display: flex;
  justify-content: center;
}
.containerPay{
  display: flex !important;
  padding: 0px !important;;
  align-items: center !important;
}
.payBtn.active{
  background-color: white !important;
  color: gray !important;
  border-radius: 1px;
  border-bottom: solid 2px #f4bc16 !important;
}
.PaymentInfo h3{
  font-weight: bold;
}
.payBtn:hover{
  background-color: white !important;
  color: gray !important;
  border-radius: 1px;
  border-bottom: solid 2px #f4bc16 !important;
}

.payBtn:after{
  background-color: white !important;
  color: gray !important;
  border-radius: 1px;
  border-bottom: solid 2px #f4bc16 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Pagamento/Pagamento.css"],"names":[],"mappings":"AAAA;EACE,YAAY;IACV,YAAY;IACZ,6BAA6B;IAC7B,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,8BAA8B;IAC9B,sBAAsB;IACtB,WAAW;IACX,qBAAqB;IACrB,mBAAmB;;AAEvB;AACA;EACE,qBAAqB;IACnB,qBAAqB;AACzB;AACA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,8BAA8B;AAChC;AACA;EACE,kCAAkC;EAClC,sBAAsB;EACtB,kBAAkB;EAClB,2CAA2C;AAC7C;AACA;EACE,iBAAiB;AACnB;AACA;EACE,kCAAkC;EAClC,sBAAsB;EACtB,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,kCAAkC;EAClC,sBAAsB;EACtB,kBAAkB;EAClB,2CAA2C;AAC7C","sourcesContent":[".payBtn{\n  height: 60px;\n    width: 100px;\n    background-color: transparent;\n    border: none;\n    display: flex;\n    text-align: center;\n    justify-content: space-between;\n    flex-direction: column;\n    color: grey;\n    align-content: center;\n    align-items: center;\n\n}\n.CardPagamento{\n  white-space: pre-wrap;\n    word-wrap: break-word;\n}\n.buttonMethod{\n  background-color: white  ;\n  border: none;\n}\n.colPay{\n  display: flex;\n  justify-content: center;\n}\n.containerPay{\n  display: flex !important;\n  padding: 0px !important;;\n  align-items: center !important;\n}\n.payBtn.active{\n  background-color: white !important;\n  color: gray !important;\n  border-radius: 1px;\n  border-bottom: solid 2px #f4bc16 !important;\n}\n.PaymentInfo h3{\n  font-weight: bold;\n}\n.payBtn:hover{\n  background-color: white !important;\n  color: gray !important;\n  border-radius: 1px;\n  border-bottom: solid 2px #f4bc16 !important;\n}\n\n.payBtn:after{\n  background-color: white !important;\n  color: gray !important;\n  border-radius: 1px;\n  border-bottom: solid 2px #f4bc16 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
